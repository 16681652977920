import React, { useContext } from "react";
import { Stack } from "@mui/material";
import updateCloseIcon from "../../../assets/updateCloseIcon.svg";
import EditAccount from "../../../routes/editAccount/EditAccount";
import { StateContext } from "../../../context/globalContext/context";

export default function EditAccountDialog() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Context
  const state = useContext(StateContext);
  const {
    setLoginCreateAccountState,

    loginCreateAccountState,

    setSignInClick,
  }: any = state;

  function handleCancel() {
    setSignInClick(false);
    // setSaveScreenState(false)
    setLoginCreateAccountState({ ...loginCreateAccountState, flag: false });
    console.log(loginCreateAccountState);
  }

  return (
    <Stack
      zIndex={1}
      sx={{
        minHeight: "100vh",
        mt: { sm: 0, xs: 2 },
        justifyContent: { sm: "center", md: "center", lg: "center" },
      }}
      spacing={6}
      alignItems="center"
      justifySelf="center"
      alignSelf="center">
      <Stack
        sx={{
          width: { xs: "100vw" },
          maxWidth: { md: "350px" },
        }}>
        <Stack
          sx={{ width: "100%" }}
          px={{ xs: 3, sm: 3, md: 0, lg: 0, xl: 0 }}>
          <Stack alignSelf="end" mb={2} mr={1.2}>
            <img
              onClick={handleCancel}
              style={{ width: "25px", cursor: "pointer" }}
              src={updateCloseIcon}
              alt="cross"
            />
          </Stack>

          <Stack
            sx={{
              width: "100%",
              maxHeight: {
                sm: "80vh",
                xs: "80vh",
                md: "100%",
                lg: "100%",
              },
              overflow: "auto",
              borderRadius: "8px",
              backgroundColor: "#f8f8f8",
            }}>
            <EditAccount />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
