import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./reorderMultipCityTrip.module.scss";
import { API_FAILURE_MESSAGE, ROUTES } from "../../constant";
import CheckboxDestinationCard from "./checkboxDestinationCard/CheckboxDestinationCard";
import { ReactSortable } from "react-sortablejs";
import { useContext, useEffect, useState } from "react";
import { StateContext } from "../../context/globalContext/context";
import { iGlobalContext } from "../../context/globalContext/interface";
import { iChatData, iCityDetailsResult } from "../../types";
import { useSessionStorage } from "../../hooks/useSessionStorage";
import toast from "react-hot-toast";
import { planTripDetails } from "../../api/planTripDetails";
import OverlayWithCancelBtn from "../../component/reusableComponent/overlayWithCancelBtn/OverlayWithCancelBtn";
import dragIcon from "../../assets/draggable.svg";
import ReOrderComponent from "../../component/reusableComponent/reOrderComponent/ReOrderComponent";
import { checkIfThereIsDestination } from "../../utils";
let abortPlanTripDetails = new AbortController();

export default function ReorderMultiCityTrip(): JSX.Element {
  const navigate = useNavigate();
  const globalContext = useContext(StateContext);
  const {
    selectedChatData,
    setOpenOverlayScreen,
    setSelectedChatData,
    setIsItinerarySavedFlag,
    setSharedItineraryId,
    setScreen,
    isMobile,
  }: iGlobalContext = globalContext;

  const handleNav = () => {
    isMobile ? navigate(ROUTES.ItineraryDetails) : setScreen("itineraryInfo");
  };

  async function handleCreateMultiCityTrip() {
    if (selectedMultiCityList.length === 0) {
      toast.error("Please select City");
      return;
    }
    const cityAndNoOfDays = selectedMultiCityList
      .filter((city) => city.checkedFlag)
      .map((city) => ({
        city_name: city.cityName,
        num_days: null,
      }));
    console.log("CITY LIST", cityAndNoOfDays);
    setOpenOverlayScreen(true);
    abortPlanTripDetails = new AbortController();
    const iteneryInput = {
      user_given_number_of_days: cityAndNoOfDays,
    };
    let response = await planTripDetails(
      abortPlanTripDetails,
      // selectedChatData?.fromCity || "",
      // String(selectedChatData.noOfDays) || "",
      // cityList
      selectedChatData.prompt,
      iteneryInput
    );
    // const response: any = await planTripDetails(
    //   abortPlanTripDetails,
    //   selectedChatData.fromCity || "",
    //   String(selectedChatData.noOfDays) || "",
    //   cityList
    // );
    setOpenOverlayScreen(false);
    console.log("YES HERE BEFORE", response);
    response = checkIfThereIsDestination(response);
    console.log("YES HERE AFTER", response);
    if (
      response?.statusCode !== 200 ||
      response?.data?.cities === undefined ||
      response?.data?.cities.length === 0
    ) {
      return;
    }
    // const detailsResponse = response.data.details;
    // console.log("DETAIL res", detailsResponse);
    // if (!detailsResponse) {
    //   return;
    // }
    let tempDestinationList = selectedMultiCityList.filter(
      (destinationListEle: iCityDetailsResult, index: number) =>
        destinationListEle.checkedFlag
    );
    tempDestinationList = selectedMultiCityList.map(
      (destinationListEle: iCityDetailsResult, index: number) => ({
        ...destinationListEle,
        tripDetails: destinationListEle.checkedFlag
          ? response?.data?.cities[index]?.tripDetails || []
          : [],
        // tripDetails: detailsResponse[index],
      })
    );
    console.log("YES HERE< TEMP", tempDestinationList);
    setSelectedChatData({
      ...selectedChatData,
      destinationList: tempDestinationList,
      tripName: tempDestinationList.reduce(
        (acc: string, ele: iCityDetailsResult, index: number) => {
          if (index === selectedChatData?.destinationList.length - 1)
            return acc + ele.cityName.split(",")[0];
          else return acc + ele.cityName.split(",")[0] + ", ";
        },
        ""
      ),
    });
    setIsItinerarySavedFlag(false);
    setSharedItineraryId("");
    handleNav();
  }
  const [selectedMultiCityList, setSelectedMultiCityList] = useSessionStorage(
    "_trip_trove_selected_multi_city_list_12",
    [] as iCityDetailsResult[]
  );
  const [unSelectedMultiCityList, setUnSelectedMultiCityList] =
    useSessionStorage(
      "_trip_trove_un_selected_multi_city_list_12",
      [] as iCityDetailsResult[]
    );
  // useEffect(() => {
  //   setSelectedMultiCityList(
  //     selectedChatData.destinationList.filter(
  //       (ele: iCityDetailsResult) => ele.checkedFlag
  //     )
  //   );
  //   setUnSelectedMultiCityList(
  //     selectedChatData.destinationList.filter(
  //       (ele: iCityDetailsResult) => !ele.checkedFlag
  //     )
  //   );
  // }, []);
  useEffect(() => {
    if (selectedChatData && Array.isArray(selectedChatData.destinationList)) {
      setSelectedMultiCityList(
        selectedChatData.destinationList.filter(
          (ele: iCityDetailsResult) => ele.checkedFlag
        )
      );
      setUnSelectedMultiCityList(
        selectedChatData.destinationList.filter(
          (ele: iCityDetailsResult) => !ele.checkedFlag
        )
      );
    }
  }, [selectedChatData]);

  function onCheckboxChange(cityDetails: iCityDetailsResult) {
    if (selectedMultiCityList.length === 1) {
      toast.error("One stop must be selected");
      return;
    }
    let tempApiResponse = selectedMultiCityList.map(
      (ele: iCityDetailsResult) => {
        if (cityDetails.id === ele.id)
          return { ...ele, checkedFlag: !ele.checkedFlag };
        return ele;
      }
    );
    const tempSelectedMultiCity = tempApiResponse.filter(
      (ele: iCityDetailsResult) => ele.checkedFlag
    );
    const tempUnSelectedMultiCity = [
      ...unSelectedMultiCityList,
      ...tempApiResponse.filter((ele: iCityDetailsResult) => !ele.checkedFlag),
    ];
    setSelectedChatData({
      ...selectedChatData,
      destinationList: [...tempSelectedMultiCity, ...tempUnSelectedMultiCity],
    });
    setSelectedMultiCityList(tempSelectedMultiCity);
    setUnSelectedMultiCityList(tempUnSelectedMultiCity);
  }

  function onUnCheckboxChange(cityDetails: iCityDetailsResult) {
    let tempApiResponse = unSelectedMultiCityList.map(
      (ele: iCityDetailsResult) => {
        if (cityDetails.id === ele.id)
          return { ...ele, checkedFlag: !ele.checkedFlag };
        return ele;
      }
    );
    const tempSelectedMultiCity = [
      ...selectedMultiCityList,
      ...tempApiResponse.filter((ele: iCityDetailsResult) => ele.checkedFlag),
    ];
    const tempUnSelectedMultiCity = tempApiResponse.filter(
      (ele: iCityDetailsResult) => !ele.checkedFlag
    );
    setSelectedChatData({
      ...selectedChatData,
      destinationList: [...tempSelectedMultiCity, ...tempUnSelectedMultiCity],
    });
    setSelectedMultiCityList(tempSelectedMultiCity);
    setUnSelectedMultiCityList(tempUnSelectedMultiCity);
  }
  // let count = 0;
  const [displayStyle, setDisplayStyle] = useState("flex");
  function handleContextMenu(event: any) {
    event.preventDefault();
  }
  return (
    <div className={styles.main}>
      <OverlayWithCancelBtn
        handleCancel={() => {
          abortPlanTripDetails.abort();
        }}
      />
      <div className={styles.reOrderCard}>
        <span className={styles.reOrder}>reorder</span>
      </div>

      <ReOrderComponent
        list={selectedMultiCityList}
        setList={setSelectedMultiCityList}
        checkboxChildren={selectedMultiCityList.map(
          (cityDetails: iCityDetailsResult, index: number) => {
            return (
              <div key={cityDetails.id}>
                <CheckboxDestinationCard
                  data={cityDetails}
                  index={index}
                  onCheckboxChange={() => onCheckboxChange(cityDetails)}
                  showSortIcon={false}
                />
              </div>
            );
          }
        )}
        sortChildren={selectedMultiCityList.map(
          (cityDetails: iCityDetailsResult, index: number) => {
            return (
              <div key={cityDetails.id}>
                <CheckboxDestinationCard
                  data={cityDetails}
                  index={index}
                  onCheckboxChange={() => onCheckboxChange(cityDetails)}
                />
              </div>
            );
          }
        )}
      />

      <div className={styles.infoTextContainer}>
        <h3>
          A minimum of{" "}
          {selectedChatData?.destinationList?.reduce(
            (acc: number, ele: iCityDetailsResult) =>
              ele.checkedFlag ? acc + 2 : acc,
            0
          )}{" "}
          days is recommended for this trip.
        </h3>
        <h1 className={styles.infoText_2}>
          You can adjust how many days you spend in each location from the next
          screen.
        </h1>
      </div>
      <Button className={styles.saveBtn} onClick={handleCreateMultiCityTrip}>
        Create Multi-City Trip
      </Button>

      <Button
        variant="outlined"
        className={styles.cancelBtn}
        onClick={() => {
          if (!isMobile) {
            setScreen("");
          }
          navigate(ROUTES.TopDestination);
        }}>
        Cancel
      </Button>

      <div className={styles.unSelectedTrips}>
        {unSelectedMultiCityList.map(
          (cityDetails: iCityDetailsResult, index: number) => {
            return (
              <div key={cityDetails.id}>
                <CheckboxDestinationCard
                  opacity={0.5}
                  data={cityDetails}
                  index={index}
                  showStop={false}
                  onCheckboxChange={() => onUnCheckboxChange(cityDetails)}
                  showSortIcon={false}
                />
              </div>
            );
          }
        )}
      </div>
    </div>
  );
}
