import styles from "./profile.module.scss";
import personaliseIcon from "../../assets/personaliseIcon.svg";
import updatedCloseIcon from "../../assets/updatedCloseIcon.svg";
import { Avatar, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constant";
import { StateContext } from "../../context/globalContext/context";
import React from "react";
import { iGlobalContext } from "../../context/globalContext/interface";
import { Box } from "@mui/system";

import { DialogSelected } from "../../constant/common";
import EditButton from "./editBtn/editBtn";
import formatPhoneNumber from "../../hooks/phoneFormater";

interface iProps {
  onClick: () => void;
}

function Profile({ onClick }: iProps): JSX.Element {
  const navigate = useNavigate();
  const state = React.useContext(StateContext);
  const {
    userDetails,
    userInfo,
    isMobile,
    setLoginCreateAccountState,
  }: iGlobalContext = state;
  const handleEditAccount = () => {
    if (isMobile) {
      setLoginCreateAccountState({
        flag: true,
        page: DialogSelected.EDIT_ACCOUNT,
      });
      onClick();
    } else {
      setLoginCreateAccountState({
        flag: true,
        page: DialogSelected.EDIT_ACCOUNT,
      });
    }
  };
  const handleEditPreference = () => {
    if (isMobile) {
      navigate(ROUTES.PreviewPreference);
      onClick();
    } else {
      setLoginCreateAccountState({
        flag: true,
        page: DialogSelected.EDIT_PREFER,
      });
    }
  };
  return (
    <div className={styles.main}>
      <div
        className={`${userDetails.email !== "" ? styles.container : styles.inActiveContainer}`}>
        <div className={styles.closeCard}>
          <img
            src={updatedCloseIcon}
            alt=""
            className={styles.closeIcon}
            onClick={onClick}
          />
        </div>
        {userDetails.email !== "" && (
          <Box
            className={styles.profileCard}
            sx={{
              pl: 2.3,
              pr: 2,
            }}>
            <div className={styles.profileBox}>
              <Avatar
                alt=""
                src={userInfo.profile_pic ? userInfo.profile_pic : ""}
                sx={{
                  width: 40,
                  height: 40,
                }}
                className={styles.avatar}
              />
              {userInfo && (
                <div className={styles.profileInfo}>
                  {userInfo.name && (
                    <p className={styles.userName}>
                      {userInfo.name.length < 15
                        ? userInfo.name
                        : userInfo.name.slice(0, 14) + "..."}
                    </p>
                  )}
                  {userInfo.email && (
                    <p className={styles.email}>
                      {userInfo.email.length < 20
                        ? userInfo.email
                        : userInfo.email.slice(0, 19) + "..."}
                    </p>
                  )}

                  {userInfo.phone && (
                    <p className={styles.number}>
                      {formatPhoneNumber(userInfo.phone)}
                    </p>
                  )}
                  {/* <p className={styles.address}>Washington, DC</p> */}
                </div>
              )}
            </div>
            <div className={styles.editCard}>
              {" "}
              <EditButton onClick={handleEditAccount} />
            </div>
          </Box>
        )}
        {isMobile ? (
          <div className={styles.persBtnCard}>
            <Button
              onClick={handleEditPreference}
              variant="text"
              endIcon={
                <img
                  src={personaliseIcon}
                  alt=""
                  className={styles.arrowIcon}
                />
              }>
              <span className={styles.personaliseBtn}>preferences</span>
            </Button>
          </div>
        ) : (
          <div className={styles.DeskBtnCard}>
            <Button
              onClick={handleEditPreference}
              variant="text"
              startIcon={
                <img
                  src={personaliseIcon}
                  alt=""
                  className={styles.arrowDeskIcon}
                />
              }>
              <span className={styles.deskBtn}>preferences</span>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Profile;
