import { loginAPI } from "../api/login";
import jwt from "jwt-decode";
import { toast } from "react-hot-toast";
import { DialogSelected } from "../constant/common";
import jwtDecode from "jwt-decode";
import { UserInfo } from "../types";

// Get JWT from local storage
export function checkIfUserIsLoggedIn(
  setUserInfo: (userInfo: UserInfo) => void
): void {
  const userJWT = window.localStorage.getItem("JWT");
  if (userJWT) {
    const decodedToken: any = jwtDecode(userJWT);
    console.log("decodedToken:-", decodedToken);

    setUserInfo({
      id: decodedToken.id,
      name: decodedToken.name,
      email: decodedToken.email,
      phone: "",
      profile_pic: "",
      loggedIn: true,
    });
  } else {
    setUserInfo({
      id: "",
      name: "",
      email: "",
      phone: "",
      profile_pic: "",
      loggedIn: false,
    });
  }
}

//  check log in and making API call
export async function checkLogin(
  email: string,
  loginType: string,
  name: string,
  password: string,
  setUserDetails: any,
  setIsLoggedIn: any,
  signInClick: any,
  setLoginCreateAccountState: any,
  loginCreateAccountState: any,
  setSignInClick: any,
  loginFailureMessage: string
) {
  const response = await loginAPI(
    email.trim(),
    loginType,
    name.trim(),
    password.trim()
  );
  // console.log(response, userDetails)
  if (response.message === "Login successfull") {
    const decodedToken: any = jwt(response.data.jwtToken);
    setUserDetails({
      id: decodedToken.id,
      name: decodedToken.name,
      email: decodedToken.email,
    });
    setIsLoggedIn(true);

    // store the JWT to local storage
    window.localStorage.setItem("JWT", JSON.stringify(response.data.jwtToken));

    if (signInClick) {
      setLoginCreateAccountState({ ...loginCreateAccountState, flag: false });
      setSignInClick(false);
    } else
      setLoginCreateAccountState({
        ...loginCreateAccountState,
        page: DialogSelected.SAVE_ITINERARY,
      });
    return decodedToken.id;
  } else {
    toast.error(loginFailureMessage);
    return "";
  }
}

// Get Local Storage Data
export function getLocalStorageData(localItem: string, setItem: any) {
  const localStorageData = window.localStorage.getItem(localItem);
  if (localStorageData) {
    setItem(JSON.parse(localStorageData));
  }

  return localStorageData;
}

// Get Local Storage Data
export function getSessionStorage(localItem: string, setItem: any) {
  const localSessionData = window.sessionStorage.getItem(localItem);
  if (localSessionData) {
    setItem(JSON.parse(localSessionData));
  }

  return localSessionData;
}
