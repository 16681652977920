import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styles from "./inputWithMic.module.scss";
import {
  WEB_SPEECH_MESSAGE,
  micIcon,
  sendIcon,
  stopMicIcon,
} from "../../../constant";
import { IconButton } from "@mui/material";
import TextareaAutosize from "react-textarea-autosize";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
// import TextField from "@mui/material/TextField";
// import InputAdornment from "@mui/material/InputAdornment";
import toast from "react-hot-toast";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";

export default function InputWithMic({
  value,
  setValue,
  onSend,
  onStopRecording = () => {},
  // handleClick = () => {},
  disable,
  handleCancel = () => {},
  setDisableInput,
  disableSendBtn,
}: {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  onSend?: any;
  onStopRecording?: any;
  // handleClick?: any;
  disable?: boolean;
  handleCancel?: () => void;
  setDisableInput?: Dispatch<SetStateAction<boolean>>;
  disableSendBtn?: boolean;
}) {
  const [pulseAnimation, setPulseAnimation] = useState({});
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  function handleStartRecording() {
    // setTimeout(() => {
    //     window.scrollTo({
    //         top: document.documentElement.scrollHeight,
    //         behavior: 'smooth'
    //     });
    // }, 1)
    if (listening) return;
    if (!browserSupportsSpeechRecognition) {
      toast.error(WEB_SPEECH_MESSAGE);
      return;
    }
    if (value === "") resetTranscript();
    SpeechRecognition.startListening({ continuous: true });
  }
  function handleStopRecording() {
    SpeechRecognition.stopListening();
    onStopRecording();
  }

  function handleOnInputChange(e: any) {
    setValue(e.target.value);
  }

  useEffect(() => {
    setValue(transcript);
  }, [transcript]);

  // useEffect(() => {
  //   window.scrollTo({
  //     top: document.documentElement.scrollHeight,
  //   });
  // }, [value]);

  /**
   * When user speaks pulse animation
   */
  useEffect(() => {
    if (listening) {
      setPulseAnimation((prev) => ({
        ...prev,
        outline: "15px solid #eee",
      }));
      setTimeout(() => {
        setPulseAnimation((prev) => ({
          ...prev,
          outline: "0px solid #eee",
        }));
      }, 100);
    }
  }, [value]);

  const handleKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      onSend();
    }
  };
  // const inputRef = useRef<HTMLDivElement | null>(null);
  // const handleFocus = () => {
  //   if (window.innerWidth < 768) {
  //     if (inputRef.current) {
  //       inputRef.current.scrollIntoView({
  //         behavior: "smooth",
  //         block: "center",
  //       });
  //     }
  //   }
  // };

  const handleSendIconClick = () => {
    setTimeout(() => {
      onSend();
    }, 0);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.inputSendBtnContainer}>
        <div className={styles.inputContainer}>
          <TextareaAutosize
            placeholder="Describe your trip"
            value={
              disable ? "" : value.charAt(0).toUpperCase() + value.slice(1)
            }
            onChange={handleOnInputChange}
            className={styles.input}
            maxRows={15}
            onKeyDown={handleKeyDown}
            spellCheck="true"
            disabled={disable}
          />
          <IconButton
            className={styles.micButton}
            onClick={() =>
              setTimeout(() => {
                handleStartRecording();
              }, 0)
            }>
            <img className={styles.micIcon} src={micIcon} alt="mic" />
          </IconButton>
        </div>
        {disable ? (
          <IconButton
            className={styles.sendButton}
            onClick={() => {
              handleCancel();
              if (setDisableInput) {
                setDisableInput(false);
              }
            }}>
            <StopCircleOutlinedIcon
              sx={{ color: "#000000", width: 35, height: 35 }}
            />
          </IconButton>
        ) : (
          <IconButton
            className={`${disableSendBtn ? styles.inActiveSendBtn : styles.sendButton}`}
            onClick={disableSendBtn ? () => {} : handleSendIconClick}>
            <img className={styles.sendIcon} alt="send" src={sendIcon} />
          </IconButton>
        )}
      </div>

      <div
        className={`
                ${styles.stopRecording}
                ${listening && styles.show}
            `}>
        <IconButton
          className={styles.disableHover}
          onClick={() =>
            setTimeout(() => {
              handleStopRecording();
            }, 0)
          }>
          <img
            className={`${styles.stopMicIcon}
                            ${listening && styles.showChild}
                        `}
            alt="stop recording"
            src={stopMicIcon}
          />
          <div
            className={`${styles.pulse}
                            ${listening && styles.showChild}
                        `}
            style={pulseAnimation}></div>
        </IconButton>
        <h1
          className={`${styles.stopText}
                    ${listening && styles.showChild}
                `}
          onClick={() =>
            setTimeout(() => {
              handleStopRecording();
            }, 0)
          }>
          Tap to stop
        </h1>
      </div>
    </div>
  );
}
