import React, { useEffect } from "react";
import { StateContext } from "../context/globalContext/context";

interface BackButtonHandlerProps {
  onBack: () => void;
}

const BackButtonHandler: React.FC<BackButtonHandlerProps> = ({ onBack }) => {
  const state = React.useContext(StateContext);
  const { nav, prevRoute }: any = state;
  // console.log("BACK FROM MOBILE")

  useEffect(() => {
    console.log("BACK FROM MOBILE  USE EFFECT");
    const handleBackButton = (event: PopStateEvent) => {
      event.preventDefault();
      onBack();
    };

    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [nav, prevRoute]);

  return null;
};

export default BackButtonHandler;

// import React, { useEffect } from 'react';

// interface BackButtonHandlerProps {
//     onBack: () => void;
// }

// const BackButtonHandler: React.FC<BackButtonHandlerProps> = ({ onBack }) => {
//     useEffect(() => {
//         const handleBackButton = () => {
//             onBack();
//         };

//         const handleBeforeUnload = (event: BeforeUnloadEvent) => {
//             event.preventDefault();
//             handleBackButton();
//             event.returnValue = ''; // Required for Chrome browsers
//         };

//         const handlePopstate = (event: PopStateEvent) => {
//             event.preventDefault();
//             handleBackButton();
//             window.history.pushState(null, '', window.location.pathname); // Add a new entry to the browser history
//         };

//         window.addEventListener('beforeunload', handleBeforeUnload);
//         window.addEventListener('popstate', handlePopstate);

//         return () => {
//             window.removeEventListener('beforeunload', handleBeforeUnload);
//             window.removeEventListener('popstate', handlePopstate);
//         };
//     }, [onBack]);

//     return null;
// };

// export default BackButtonHandler;
