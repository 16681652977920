import { useContext, useEffect, useRef, useState } from "react";
import {
  logoIcon,
  zenvoyaChatIcon,
  ROUTES,
  VIEW_RECOMMENDED_TRIPS,
  SENDING_CHAT_MESSAGE,
  scrollRightBtnIcon,
} from "../../../constant";

import { getFrequentlyViewTripsApi } from "../../../api/getFrequentlyViewTrips";
import DestinationCard from "../../reusableComponent/destinationCard/DestinationCard";
import { useSessionStorage } from "../../../hooks/useSessionStorage";
import InputWithMic from "../../reusableComponent/inputWithMic/InputWithMic";
import { iGlobalContext } from "../../../context/globalContext/interface";
import { StateContext } from "../../../context/globalContext/context";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import SpeechRecognition from "react-speech-recognition";
import { chatInteractionAPI } from "../../../api/chatInteractionAPI";
import {
  checkIfThereIsDestination,
  getConfigMessage,
  scrollToBottom,
} from "../../../utils";
import { v4 } from "uuid";
import { getItineraryImageURL } from "../../../api/getItineraryImageURL";
import Loader from "../../reusableComponent/loader/Loader";
import styles from "./recomm.module.scss";
import InitialChat from "../initialChat/initialChat";
import { RESPONSE_DUMMY } from "../../../constant/responseDummy";
import { localStorageName, ScreenTypes } from "../../../enums";
import { iAppConfig, iCityDetailsResult } from "../../../types";
import ReactGA from "react-ga4";
import { toastMessage } from "../../../helpers/toast/toastMessage";
import { APP_CONFIG } from "../../../constant/common";
import Prompts from "../../prompts/prompts";

function RecommendedTrips(): JSX.Element {
  const context = useContext(StateContext);
  const {
    chatInputText,
    setChatInputText,
    setShowMoreFlag,
    setApiResponse,
    setOpenOverlayScreen,
    setChatData,
    setSharedItineraryId,
    setIsItinerarySavedFlag,
    setenableMultiSelect,
    setScreen,
    openDetailsSliderFlag,
    setCallSaveHistoryAPIFlag,
    userDetails,
    appConfig,
  }: iGlobalContext = context;
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [disableInput, setDisableInput] = useState<boolean>(false);
  const [temFreqViewTrips, setFreqViewTrips] = useSessionStorage(
    localStorageName.TriptroveFrequentlyViewTrips,
    []
  );
  const [index, setIndex] = useState({
    first: 0,
    last: 3,
  });
  useEffect(() => {
    setIndex({
      first: 0,
      last: temFreqViewTrips.length - 1,
    });
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 5320,
      });
    }
  }, [temFreqViewTrips]);

  const [frequentlyViewTrips, setFrequentlyViewTrips] = useState([] as any[]);
  async function getFrequentlyViewTrips() {
    const response = await getFrequentlyViewTripsApi();
    if (response && response?.statusCode === 200) {
      setFreqViewTrips(response?.data);
      setFrequentlyViewTrips([
        ...response?.data,
        ...response?.data,
        ...response?.data,
        ...response?.data,
        ...response?.data,
        ...response?.data,
        ...response?.data,
      ]);
    } else {
    }
  }
  useEffect(() => {
    getFrequentlyViewTrips();
  }, []);

  const navigate = useNavigate();
  async function handleCreateItinerary() {
    setCallSaveHistoryAPIFlag(false);
    scrollToBottom(scrollContainerRef);
    setenableMultiSelect(false);
    if (chatInputText === "") {
      toastMessage.info("Please type your message!");
      return;
    }
    setOpenOverlayScreen(true);
    setDisableInput(true);
    SpeechRecognition.stopListening();
    setApiResponse([]);
    setShowMoreFlag(false);
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    let response = await chatInteractionAPI(
      userDetails?.id || "",
      chatInputText,
      [],
      abortController.signal
    );
    if (!response) {
      toast.error(
        getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      );
      // setChatData(chatData.splice(0, -1))
      setDisableInput(false);
      return;
    }
    setOpenOverlayScreen(false);
    response = checkIfThereIsDestination(response);
    const citiesList = response?.data?.cities;
    if (citiesList?.length > 0) {
      const tempResponse = citiesList.map((ele: any) => ({
        ...ele,
        id: v4(),
        // checkedFlag: citiesList?.length === 1 ? true : false,
        // checkedFlag: response?.data?.singleCity,
        checkFlag: false,
        locations: [],
      }));
      setChatData([
        {
          id: v4(),
          message: chatInputText,
          isUserMessage: true,
          prompt: [],
          isLoading: false,
          destinationList: [],
          singleSelectedDestination: false,
        },
        {
          fromCity: response?.data?.from,
          noOfDays: response?.data?.numberOfdays,
          id: v4(),
          message: "",
          preText: response?.data?.preText,
          postText: response?.data?.postText,
          prompt: response?.data?.messages,
          isUserMessage: false,
          isLoading: false,
          singleSelectedDestination: response?.data?.singleCity,
          destinationList: tempResponse,

          complete_itinerary_description:
            RESPONSE_DUMMY.complete_itinerary_description,
          city_wise_itinerary: RESPONSE_DUMMY.city_wise_itinerary,
        },
      ]);
      const currentItineraryList = tempResponse;
      for (let i = 0; i < currentItineraryList.length; i++) {
        const cityName = currentItineraryList[i].cityName;
        let imageRespone: any = await getItineraryImageURL(cityName);
        let count = 0;
        while (!imageRespone || !imageRespone?.data?.image) {
          // Limiting the number of API call to 10 times incase of 409 error
          count++;
          if (count === 10) break;
          // re calling the API
          imageRespone = await getItineraryImageURL(cityName);
        }
        if (imageRespone?.statusCode === 200) {
          currentItineraryList[i].imageURL = imageRespone.data.image;
        } else {
          console.log("RESPONSE TOP Error");
        }
      }
    } else {
      setChatData([
        {
          id: v4(),
          message: chatInputText,
          isUserMessage: true,
          prompt: [],
          isLoading: false,
          destinationList: [],
          singleSelectedDestination: false,
        },
        {
          id: v4(),
          message: response?.data?.result,
          prompt: response?.data?.messages,
          isUserMessage: false,
          isLoading: false,
          destinationList: [],
          singleSelectedDestination: false,
        },
      ]);
    }
    navigate(ROUTES.TopDestination);
    setOpenOverlayScreen(false);
    setDisableInput(false);
    setScreen("");
    ReactGA.event({
      category: SENDING_CHAT_MESSAGE.CATEGORY,
      action: SENDING_CHAT_MESSAGE.ACTION,
      label: ` ${SENDING_CHAT_MESSAGE.LABEL}  ${chatInputText}`,
    });
    setCallSaveHistoryAPIFlag(true);
  }

  const [, setSelectedRecommendedTripId] = useState("");

  function handleViewItineraryDetails(data: any) {
    setSelectedRecommendedTripId(data.id);
    setSharedItineraryId(`${data.id}` || "");
    setIsItinerarySavedFlag(true);
    ReactGA.event({
      category: VIEW_RECOMMENDED_TRIPS.CATEGORY,
      action: VIEW_RECOMMENDED_TRIPS.ACTION,
      label: ` ${VIEW_RECOMMENDED_TRIPS.LABEL}  ${data?.tripName}`,
    });
    setScreen(ScreenTypes.SavedItinerary);
  }

  useEffect(() => {
    if (openDetailsSliderFlag === false) setSelectedRecommendedTripId("");
  }, [openDetailsSliderFlag]);

  // const scrollHandler = (elementRef: any) => {
  //   elementRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  // };

  const handleCancel = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      setOpenOverlayScreen(false);
      setChatInputText("");
      setDisableInput(false);
    }
  };
  function handleScrollLeft() {
    if (index.first > 0 && sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -190,
        behavior: "smooth",
      });
      setIndex({
        first: index.first - 1,
        last:
          (index.last - 1 + temFreqViewTrips.length) % temFreqViewTrips.length,
      });
    }
  }

  function handleScrollRight() {
    setFrequentlyViewTrips([
      ...frequentlyViewTrips,
      temFreqViewTrips[index.first],
    ]);
    setIndex({
      first: (index.first + 1) % temFreqViewTrips.length,
      last: (index.last + 1) % temFreqViewTrips.length,
    });
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: 190,
        behavior: "smooth",
      });
    }
  }
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.contentBox}>
          <div className={styles.contentCard} ref={scrollContainerRef}>
            <div className={styles.logoCard}>
              <img className={styles.logo} alt="logo" src={logoIcon} />
            </div>
            <div className={styles.systemChatIcon}>
              <div className={styles.titleCard}>
                <div className={styles.title}>
                  <img
                    className={styles.chatIcon}
                    alt="chat logo"
                    src={zenvoyaChatIcon}
                  />
                  <p>zenvoya</p>
                </div>
                <div
                  className={styles.constText}
                  dangerouslySetInnerHTML={{
                    __html:
                      appConfig?.length > 0
                        ? appConfig?.filter(
                            (ele: iAppConfig) =>
                              ele.key === APP_CONFIG.HOME_CONSTANT_1
                          )[0]?.value
                        : "",
                  }}
                />
                {/* <span className={styles.desc}>
                  Include things like food preferences, activities, weather,
                  <br />
                  time of year, locations or cultural experiences.
                </span> */}
              </div>
            </div>
            <div className={styles.horizontalScrollContainer}>
              {frequentlyViewTrips.length > 0 && (
                <div className={styles.recommendedTripsContainer}>
                  {index.first > 0 && (
                    <div
                      onClick={handleScrollLeft}
                      className={styles.leftBtnContainer}>
                      <img
                        className={styles.leftBtn}
                        src={scrollRightBtnIcon}
                        alt="left btn"
                      />
                    </div>
                  )}

                  <div className={styles.scrollContainer} ref={sliderRef}>
                    {frequentlyViewTrips.map((ele: any, index: number) => (
                      <DestinationCard
                        width="180px"
                        key={index}
                        destinationData={
                          JSON.parse(
                            ele.metaData
                          )[0]?.selectedChatData?.destinationList?.filter(
                            (ele: iCityDetailsResult) => ele.checkedFlag
                          )[0]
                        }
                        onClick={() => handleViewItineraryDetails(ele)}
                        selectable={false}
                      />
                    ))}
                  </div>
                  <div
                    onClick={handleScrollRight}
                    className={styles.rightBtnContainer}>
                    <img
                      className={styles.rightBtn}
                      src={scrollRightBtnIcon}
                      alt="right btn"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className={styles.infoCard}>
              <div
                className={styles.info}
                dangerouslySetInnerHTML={{
                  __html:
                    appConfig?.length > 0
                      ? appConfig?.filter(
                          (ele: iAppConfig) =>
                            ele.key === APP_CONFIG.HOME_CONSTANT_2
                        )[0]?.value
                      : "",
                }}
              />
            </div>
            <div className={styles.chatBox}>
              {disableInput && <InitialChat message={chatInputText} />}
            </div>
            {disableInput && <Loader />}
            {!disableInput && <Prompts />}
          </div>
        </div>
        <div className={styles.searchCard}>
          <div className={styles.inputBox}>
            <InputWithMic
              onStopRecording={handleCreateItinerary}
              onSend={handleCreateItinerary}
              value={chatInputText}
              setValue={setChatInputText}
              disable={disableInput}
              handleCancel={handleCancel}
              setDisableInput={setDisableInput}
              disableSendBtn={chatInputText === "" ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecommendedTrips;
