export function formatDate(dateString: string) {
  const date = new Date(dateString);

  // Get month, day, and year
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}

export function sortDatesInAscendingOrder(array: any, dateKey: string) {
  return array.sort((a: any, b: any) =>    new Date(b[dateKey]).valueOf() - new Date(a[dateKey]).valueOf());
}

