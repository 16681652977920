import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import styles from "./cityTabs.module.scss";
import { Skeleton, Stack, Drawer } from "@mui/material";
import { iCityDetailsResult } from "../../../types";
import Markdown from "react-markdown";
import BudgetEstimates from "../budgetEstimates/BudgetEstimates";
import { multiCityIcon } from "../../../constant";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  data?: iCityDetailsResult;
  noOfCitySelected: number;
}

/**
 * Helper component
 */
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, data, noOfCitySelected, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={styles.customTabPanelContainer}
    >
      {value === index && (
        <>
          <div className={styles.imageContainer}>
            <div className={styles.overlayContainer}></div>
            <div className={styles.placeHeadingContainer}>
              <h1> {data?.cityName.split(",")[0]}</h1>
              <h2>
                {" "}
                {
                  data?.cityName.split(",")[
                    data?.cityName.split(",").length - 1
                  ]
                }
              </h2>
            </div>
            {noOfCitySelected > 1 && (
              <div className={styles.multiCityIconContainer}>
                <img src={multiCityIcon} alt="" />
                <h1>MULTI-CITY</h1>
              </div>
            )}
            <img
              className={styles.image}
              src={data?.imageURL ? data?.imageURL : "https://error.error"}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/destinationPlaceholder.png";
                currentTarget.style.objectFit = "cover";
                currentTarget.style.height = "100%";
                currentTarget.style.width = "100%";
              }}
              alt="image"
            />
          </div>
          <p className={styles.promptText}>{data?.description}</p>
          <Stack>
            {/* <pre>{JSON.stringify(data?.tripDetails, null, 2)}</pre> */}
            {data?.tripDetails?.map((ele: any, index: number) => (
              <Stack pb={3} key={index}>
                <Stack
                  direction={"row"}
                  gap={1}
                  alignItems={"center"}
                  ml={"-20px"}
                >
                  <h1
                    style={{
                      backgroundColor: "#D8EFFF",
                      padding: "6px 12px",
                      width: "max-content",
                      color: "#696969",
                      fontSize: "10px",
                      borderRadius: "0  5px 0 0 ",
                      textTransform: "uppercase",
                      fontWeight: "800",
                      flexShrink: 0,
                    }}
                  >
                    {ele?.day}
                  </h1>
                  <h1 className={styles.title}>
                    {ele?.day_itinerary_description}
                  </h1>
                </Stack>

                <Stack
                  pt={1}
                  spacing={1}
                  sx={{
                    mx: { xs: 1},
                  }}
                >
                  {/* {ele.activities.length === 0 && (
                    <div className={styles.timeInADay}>
                      <Skeleton></Skeleton>
                      <Skeleton sx={{ width: "80%" }}></Skeleton>
                      <Skeleton></Skeleton>
                      <Skeleton sx={{ width: "80%" }}></Skeleton>
                    </div>
                  )} */}
                  {ele?.itinerary_by_time_of_the_day?.map(
                    (subEle: any, index: number) => {
                      return (
                        <div className={styles.timeInADay} key={index}>
                          <h1 className={styles.title}>
                            {subEle?.time_of_the_day}
                          </h1>
                          <Markdown className={styles.description}>
                            {subEle?.description}
                          </Markdown>
                        </div>
                      );
                    }
                  )}
                </Stack>
              </Stack>
            ))}
          </Stack>

          <div className={styles.budgetEstimatesContainer}>
            <BudgetEstimates
              noOfCitySelected={noOfCitySelected}
              data={data}
              hotelsCost={data?.complete_itinerary_hotels_cost}
              flightsCost={data?.complete_itinerary_flights_cost}
            />
          </div>
        </>
      )}
    </div>
  );
}

/**
 * Helper component
 */
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/**
 * Main component
 */
export default function CityTabs({
  destinationList,
}: {
  destinationList: iCityDetailsResult[];
}) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      {destinationList?.reduce(
        (acc: number, destListEle: iCityDetailsResult) => {
          if (destListEle.checkedFlag) return acc + 1;
          else return acc;
        },
        0
      ) > 1 && (
        <Box sx={{ height: 40 }}>
          <Tabs
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="scrollable auto tabs example"
            sx={{
              maxWidth: { xs: "100%", sm: 480 },
              borderBottom: "none",

              "& .MuiTabs-indicator": {
                display: "none",
              },
            }}
          >
            {destinationList?.map((ele: iCityDetailsResult, index: number) => (
              <Tab
                label={
                  <div className={styles.tabTextContainer}>
                    <h1
                      className={`${styles.tabHeading} ${index !== value && styles.notSelected}`}
                    >
                      {ele?.cityName.split(",")[0]}
                    </h1>
                    <h2 className={`${index !== value && styles.notSelected}`}>
                      {
                        ele?.cityName.split(",")[
                          ele?.cityName.split(",").length - 1
                        ]
                      }
                    </h2>
                  </div>
                }
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </Box>
      )}
      {destinationList?.map((ele: iCityDetailsResult, index: number) => (
        <CustomTabPanel
          value={value}
          index={index}
          data={ele}
          noOfCitySelected={destinationList.length}
        />
      ))}
    </div>
  );
}