import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import HistoryTab from "../history/history";
import updatedNewTrip from "../../../assets/updatedNewTrip.png";
import {
  arrowIcon,
  calenderIcon,
  newTripIcon,
  ROUTES,
} from "../../../constant";
import { useNavigate } from "react-router-dom";
import SaveTrips from "../../savedTrips/savedTrips";
import { Itineraries } from "../../../types";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";
import { Button } from "@mui/material";
import { getItinerariesListAPI } from "../../../api/getSaveItinerariesList";
import { sortDatesInAscendingOrder } from "../../../utils/dateUtils";
import toast from "react-hot-toast";
import styles from "./tab.module.scss";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface iProps {
  isOpenMobileSidebar?: boolean;
  setIsOpenMobileSidebar?: Dispatch<SetStateAction<boolean>>;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box
          sx={{
            pl: 2.3,
            pt: 1,
            pr: 2,
            overflow: "hidden",
          }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({
  setIsOpenMobileSidebar,
  isOpenMobileSidebar,
}: iProps) {
  const [value, setValue] = useState<number>(1);
  const [tripCount, setTripCount] = useState<number>(0);
  const navigate = useNavigate();
  const [listItinery, setListItinery] = useState<Itineraries | null>(null);
  const [todayHistory, setTodayHistory] = useState([]);
  const [yesterdayHistory, setYesterdayHistory] = useState([]);
  const [oneWeekAgoHistory, setOneWeekAgoHistory] = useState([]);
  const [lastOneMonth, setLastOneMonth] = useState([]);
  const globalContext = useContext(StateContext);
  const {
    isMobile,
    setScreen,
    userInfo,
    trigerredListItineraryAPIFlag,
    setTrigerredListItineraryAPIFlag,
    userDetails,
  }: iGlobalContext = globalContext;

  /**
   * Handling tabs and switching between tabs
   */
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleNav = () => {
    navigate(ROUTES.Home);
    setScreen("");
  };
  useEffect(() => {
    setValue(0);
    setTimeout(() => {
      if (isMobile) setValue(0);
      else setValue(1);
    }, 500);
  }, []);

  const handleLastTab = () => {
    if (setIsOpenMobileSidebar) setIsOpenMobileSidebar(false);
    navigate(ROUTES.Home);
    setValue(value);
  };

  /**
   * Save trip login
   */
  const [isLoadingItineraryList, setIsLoadingItineraryList] = useState(false);
  async function getListItineries() {
    try {
      setIsLoadingItineraryList(true);
      // setTripCount(0)
      const response = await getItinerariesListAPI(userDetails.id);
      setIsLoadingItineraryList(false);
      if (response && response.data) {
        setListItinery(sortDatesInAscendingOrder(response.data, "createdAt"));
        console.log(response.data);
        setTripCount(response.data.length);
      } else {
        throw new Error("No response data");
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
    setTrigerredListItineraryAPIFlag(false);
  }
  useEffect(() => {
    getListItineries();
  }, []);
  useEffect(() => {
    console.log(trigerredListItineraryAPIFlag);
    if (trigerredListItineraryAPIFlag) getListItineries();
  }, [trigerredListItineraryAPIFlag]);

  useEffect(() => {
    if (isOpenMobileSidebar) getListItineries();
  }, [isOpenMobileSidebar]);

  /**
   * React component
   */
  if (!userInfo.loggedIn && !isMobile)
    return (
      <div className={styles.main}>
        <Button className={styles.headBox} onClick={handleNav}>
          <p className={styles.title}>New Trip</p>
          <img src={newTripIcon} alt="" className={styles.editLogo} />
        </Button>
      </div>
    );

  return (
    <div className={styles.main}>
      {!isMobile && (
        <Button className={styles.headBox} onClick={handleNav}>
          <p className={styles.title}>New Trip</p>
          <img src={newTripIcon} alt="" className={styles.editLogo} />
        </Button>
      )}

      {!isMobile && (
        <img className={styles.arrowButton} src={arrowIcon} alt="" />
      )}

      {!isMobile && <div className={styles.divider}></div>}

      <Box className={styles.box}>
        {isMobile ? (
          <Tabs
            orientation={"horizontal"}
            value={value}
            onChange={handleChange}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "none",
              "& .MuiTab-root": {
                color: "#8F8F8F",
                fontSize: 12,
                fontFamily: "Lato",
                fontWeight: "600",
              },
              "& .Mui-selected": {
                color: "#47174C !important",
                fontWeight: "700",
              },
              "& .MuiTabs-indicator": {
                display: "none",
              },
            }}>
            <Tab
              label="HISTORY"
              {...a11yProps(0)}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
              onClick={() => setValue(0)}
            />

            <Tab
              label={`saved trips ${tripCount > 0 ? `(${tripCount})` : ""}`}
              {...a11yProps(1)}
              sx={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            />
            <Tab
              icon={
                <img
                  src={updatedNewTrip}
                  alt=""
                  style={{ width: "auto", height: "20px" }}
                />
              }
              label="New Trip"
              iconPosition="start"
              {...a11yProps(2)}
              sx={{ alignSelf: "flex-end", mr: 0.5 }}
              onClick={handleLastTab}
            />
          </Tabs>
        ) : (
          <Tabs
            orientation={"vertical"}
            value={value}
            onChange={handleChange}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "none",
              "& .MuiTab-root": {
                color: "#8F8F8F",
                fontSize: 11,
                fontFamily: "Lato",
                fontWeight: "600",
              },
              "& .Mui-selected": {
                color: "#47174C !important",
                fontWeight: "800",
              },
              "& .MuiTabs-indicator": {
                display: "none",
              },
            }}>
            <Tab
              disableTouchRipple
              icon={
                <img
                  src={calenderIcon}
                  alt=""
                  style={{ width: "auto", height: "15px" }}
                />
              }
              iconPosition="start"
              label={`saved trips ${tripCount > 0 ? `(${tripCount})` : ""}`}
              {...a11yProps(0)}
              sx={{
                mx: "20px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                height: "20px !important",
                padding: 0,
                marginBottom: "-40px",
                marginTop: "40px",
              }}
            />
            <Tab
              disableTouchRipple
              label="HISTORY"
              {...a11yProps(1)}
              sx={{
                // mx: "20px",
                ml: "3px",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                //  borderTop: "0.5px solid #979797"
              }}
              onClick={() => setValue(1)}
            />
          </Tabs>
        )}
      </Box>
      <CustomTabPanel value={value} index={isMobile ? 0 : 1}>
        <HistoryTab
          todayHistory={todayHistory}
          setTodayHistory={setTodayHistory}
          yesterdayHistory={yesterdayHistory}
          setYesterdayHistory={setYesterdayHistory}
          oneWeekAgoHistory={oneWeekAgoHistory}
          setOneWeekAgoHistory={setOneWeekAgoHistory}
          lastOneMonth={lastOneMonth}
          setLastOneMonth={setLastOneMonth}
          isOpenMobileSidebar={isOpenMobileSidebar}
          setIsOpenMobileSidebar={setIsOpenMobileSidebar}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={isMobile ? 1 : 0}>
        <SaveTrips
          value={value}
          setTripCount={setTripCount}
          setIsOpenMobileSidebar={setIsOpenMobileSidebar}
          tripCount={tripCount}
          listItinery={listItinery}
          setListItinery={setListItinery}
          isLoadingItineraryList={isLoadingItineraryList}
          setIsLoadingItineraryList={setIsLoadingItineraryList}
        />
      </CustomTabPanel>
    </div>
  );
}
