import React from "react";
import Dialog from "@mui/material/Dialog";
import styles from "./discard.module.scss";

export interface dialogProps {
  open: boolean;
  onClose: () => void;
  handleDiscard: () => void;
}

function DiscardChanges(props: dialogProps) {
  const { onClose, open, handleDiscard } = props;

  return (
    <Dialog
      onClose={onClose}
      open={open}
      className={styles.main}
      BackdropProps={{
        sx: {
          backgroundColor: "rgba(0,0,0,0.85)",
        },
      }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}>
      <div className={styles.container}>
        <span className={styles.title}>Discard Changes?</span>
        <span className={styles.subTitle}>
          Your changes will be lost if
          <br /> you do not save them!
        </span>
        <div className={styles.btnCard}>
          <button className={styles.cancelBtn} onClick={onClose}>
            Cancel
          </button>
          <button className={styles.discBtn} onClick={handleDiscard}>
            Discard Changes
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export default DiscardChanges;
