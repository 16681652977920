import React, { ReactNode } from "react";
import { StateProvider } from "./globalContext/context";
import { TopDestinationContextProvider } from "./topDestinationContext/TopDestinationContext";

export default function ContextProvider({ children }: { children: ReactNode }) {
  return (
    <StateProvider>
      <TopDestinationContextProvider>{children}</TopDestinationContextProvider>
    </StateProvider>
  );
}
