import { Button } from "@mui/material";
import { BudgetTypes, ButtonSizes, ButtonVariants } from "../../../../enums";
import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { ENDPOINT } from "../../../../constant/environment";
import React from "react";
import toast from "react-hot-toast";
import updatedCloseIcon from "../../../../assets/updatedCloseIcon.svg";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import styles from "./preview.module.scss";
import Budget from "../../../budget/budget";
import Experience from "../../../previewPreference/experience/experience";
import Leave from "../../../previewPreference/leave/leave";
import Accommodations from "../../../previewPreference/accomodations/accomodations";
import FoodPreferences from "../../../previewPreference/foodPreferences/foodPreferences";
import { iGlobalContext } from "../../../../context/globalContext/interface";
import { StateContext } from "../../../../context/globalContext/context";
import { UpdateStateTypes } from "../../../../types";
import Loader from "../../../reusableComponent/loader/Loader";
import EditButton from "../../../editBtn/editBtn";
import { SELECTED_PREFERENCES } from "../../../../constant";
import DiscardChanges from "../../discardChanges/discardChanges";
import AutocompleteAddress from "../../../autoCompleteAddress/autoCompleteAddress";
import { toastMessage } from "../../../../helpers/toast/toastMessage";
import { APP_CONFIG } from "../../../../constant/common";
import { getCategoryAPICall } from "../../../../api/getCategory";
import { getConfigMessage } from "../../../../utils";

function PreviewPreference(): JSX.Element {
  const state = React.useContext(StateContext);
  const firstSectionRef = useRef<HTMLDivElement | null>(null);
  const {
    userDetails,
    setLoginCreateAccountState,
    loginCreateAccountState,
    isMobile,
    appConfig,
  }: iGlobalContext = state;
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [update, setUpdate] = useState<UpdateStateTypes>({
    city: false,
    climate: false,
    population: false,
    exp: false,
    duration: false,
  });
  const [city, setCity] = useState("");
  const [isFormUpdated, setIsFormUpdated] = useState<boolean>(false);
  const [budget, setBudget] = useState<BudgetTypes>(BudgetTypes.Economy);

  const [scrollValue, setScrollValue] = useState<number>(3);
  const [prefId, setPrefId] = useState<string>("");

  const [selectedActivities, setSelectedActivities] = useState<string[]>([]);

  const [population, setPopulation] = useState<string[]>([]);

  const [selectedMonth, setSelectedMonth] = useState<string[]>(["Anytime"]);

  const [selectedAccomodation, setSelectedAccomodation] = useState<string[]>(
    []
  );

  const [selectedFood, setSelectedFood] = useState<string[]>([]);

  const [climate, setClimate] = useState<string[]>([]);
  const [selectedLocation, setSelectedLocation] = useState({
    city: "",
    state: "",
    country: "",
  });
  const updateSuccessMessage =
    appConfig.find((item) => item?.key === APP_CONFIG.TOAST_PREFERENCE_UPDATE)
      ?.value ?? "Your preferences are updated!";
  const addSuccessMessage =
    appConfig.find((item) => item?.key === APP_CONFIG.TOAST_PREFERENCE_ADD)
      ?.value ?? "Your preferences are added!";

  const handleScroll = () => {
    if (firstSectionRef.current) {
      firstSectionRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const handleUpdate = () => {
    setUpdate({ ...update, city: !update.city });
  };

  const handleUpdateUserData = useCallback((userData: any) => {
    if (userData) {
      const { city, state, country } = userData.homeCity || {};
      setCity([city, state, country].filter(Boolean).join(" "));
      setSelectedLocation({
        city: city ?? "",
        state: state ?? "",
        country: country ?? "",
      });
      setSelectedMonth(userData.travelMonth);
      setClimate(userData.climate ? userData.climate.flat() : []);
      setPopulation(userData.population ? userData.population.flat() : []);
      setSelectedAccomodation(
        userData.accommodations ? userData.accommodations.flat() : []
      );
      setSelectedFood(
        userData.foodPreferences ? userData.foodPreferences.flat() : []
      );
      setBudget(userData.budget);
      setPrefId(userData.id ?? "");
      setScrollValue(userData.duration ?? "");
      setSelectedActivities(
        userData.experiences ? userData.experiences.flat() : []
      );
      setShowLoader(false);
    }
  }, []);

  const handleEditAllFields = useCallback(() => {
    setUpdate({
      city: false,
      climate: false,
      duration: false,
      exp: false,
      population: false,
    });
  }, []);

  const getApiData = useCallback(async () => {
    setShowLoader(true);
    try {
      const res = await axios.get(
        `${ENDPOINT}/app/v1/users/preferences/?userId=${userDetails.id}`
      );
      const data = res.data.data;
      if (data) {
        handleUpdateUserData(data);
        handleEditAllFields();
      }
      if (data && data.length === 0) {
        setUpdate({
          ...update,
          city: true,
        });
      }
    } catch (error) {
      toastMessage.error(
        getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      );
    } finally {
      setShowLoader(false);
    }
  }, [userDetails.id, handleUpdateUserData, handleEditAllFields, appConfig]);

  const getCategory = useCallback(async () => {
    const storedCategory = window.localStorage.getItem("_all_category");
    if (storedCategory) {
      await getApiData();
    } else {
      try {
        const response = await getCategoryAPICall();
        if (response && response) {
          window.localStorage.setItem(
            "_all_category",
            JSON.stringify(response)
          );
          await getApiData();
        } else {
          console.error("Failed to fetch the data");
        }
      } catch (error) {
        console.error(error);
        toastMessage.error(
          getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
        );
      }
    }
  }, [appConfig, getApiData]);

  useEffect(() => {
    getCategory();
  }, [getCategory]);

  const handleAddUserPreferences = async () => {
    if (!city) {
      toast.error("Please enter city");
      return;
    }

    const payload = {
      userId: userDetails.id,
      homeCity: selectedLocation,
      climate,
      population,
      experiences: selectedActivities,
      travelMonth: selectedMonth,
      accommodations: selectedAccomodation,
      foodPreferences: selectedFood,
      budget,
      duration: scrollValue.toString(),
    };

    try {
      const response = await axios.post(
        `${ENDPOINT}/app/v1/users/preferences`,
        payload
      );
      if (response.data && response.data.statusCode === 200) {
        handleScroll();
        getApiData();
        setIsFormUpdated(false);
        setLoginCreateAccountState({ ...loginCreateAccountState, flag: false });
        ReactGA.event({
          category: SELECTED_PREFERENCES.CATEGORY,
          action: SELECTED_PREFERENCES.ACTION,
          label: ` ${SELECTED_PREFERENCES.LABEL}  ${payload}`,
        });
        toastMessage.success(addSuccessMessage);
      } else {
        toastMessage.error(
          getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
        );
      }
    } catch (error) {
      console.error("Error:", error);
      toastMessage.error(
        getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      );
    }
  };

  const handleUpdateUserPreference = async () => {
    if (!city) {
      toast.error("Please enter city");
      return;
    }

    const payload = {
      id: prefId,
      userId: userDetails.id,
      homeCity: selectedLocation,
      climate,
      population,
      experiences: selectedActivities,
      travelMonth: selectedMonth,
      accommodations: selectedAccomodation,
      foodPreferences: selectedFood,
      budget,
      duration: scrollValue.toString(),
    };

    try {
      const response = await axios.put(
        `${ENDPOINT}/app/v1/users/preferences/?userId=${userDetails.id}`,
        payload
      );
      if (response.data && response.data.statusCode === 200) {
        getApiData();
        handleScroll();
        setIsFormUpdated(false);
        setLoginCreateAccountState({ ...loginCreateAccountState, flag: false });
        ReactGA.event({
          category: SELECTED_PREFERENCES.CATEGORY,
          action: SELECTED_PREFERENCES.ACTION,
          label: ` ${SELECTED_PREFERENCES.LABEL}  ${payload}`,
        });
        toastMessage.success(updateSuccessMessage);
      } else {
        toastMessage.error(
          getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
        );
      }
    } catch (error) {
      toastMessage.error(
        getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      );
      console.error("Error:", error);
    }
  };

  // useEffect(() => {
  //   getApiData();
  // }, []);

  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.value.length <= 50) {
  //     setCity(e.target.value);
  //     setIsFormUpdated(true);
  //   }
  // };

  const handleBack = () => {
    if (isMobile) {
      navigate("/");
      setLoginCreateAccountState({ ...loginCreateAccountState, flag: false });
    } else {
      setLoginCreateAccountState({ ...loginCreateAccountState, flag: false });
    }
  };

  // function handleSkip() {
  //   // setSaveScreenState(false)
  //   setLoginCreateAccountState({ ...loginCreateAccountState, flag: false });
  // }

  const handleClickOpen = () => {
    isFormUpdated ? setOpen(true) : handleBack();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDiscard = () => {
    if (isFormUpdated) {
      getApiData();
      setOpen(false);
      setIsFormUpdated(false);
      navigate("/");
      setLoginCreateAccountState({ ...loginCreateAccountState, flag: false });
    }
  };

  const handlePlaceSelected = () => {
    setIsFormUpdated(true);
  };

  return (
    <div className={styles.main}>
      {showLoader ? (
        <section className={styles.loader}>
          <Loader />
        </section>
      ) : (
        <div className={styles.container}>
          <DiscardChanges
            open={open}
            onClose={handleClose}
            handleDiscard={handleDiscard}
          />
          <section className={styles.titleCard} ref={firstSectionRef}>
            <div className={styles.titleSubCard}>
              <div className={styles.closeCard} onClick={handleClickOpen}>
                <img
                  src={updatedCloseIcon}
                  alt=""
                  className={styles.closeIcon}
                />
                <span className={styles.closeText}>Close</span>
              </div>
              <span className={styles.edit}>
                {prefId === "" ? "Add Preferences" : "Edit Preferences"}
              </span>
            </div>
          </section>

          <section className={styles.cityCard}>
            <div className={styles.cityBox}>
              <div className={styles.cityDiv}>
                <span className={styles.myCity}>My home city: </span>
                {update.city ? (
                  <AutocompleteAddress
                    onPlaceSelected={handlePlaceSelected}
                    selectedLocation={selectedLocation}
                    setSelectedLocation={setSelectedLocation}
                    setAddress={setCity}
                    address={city}
                    setIsFormUpdated={setIsFormUpdated}
                  />
                ) : (
                  <span className={styles.city}>
                    {[
                      selectedLocation.city,
                      selectedLocation.state,
                      selectedLocation.country,
                    ]
                      .filter(Boolean)
                      .join(", ")}
                  </span>
                )}
              </div>
              {!update.city && (
                <div className={styles.editBox}>
                  <EditButton onClick={handleUpdate} />
                </div>
              )}
            </div>
          </section>

          <section className={styles.budget}>
            <Budget
              setBudget={setBudget}
              budget={budget}
              setIsFormUpdated={setIsFormUpdated}
            />
          </section>
          <section className={styles.exp}>
            <Leave
              update={update}
              setUpdate={setUpdate}
              selectedMonth={selectedMonth}
              setSelectedMonth={setSelectedMonth}
              setScrollValue={setScrollValue}
              scrollValue={scrollValue}
              setIsFormUpdated={setIsFormUpdated}
            />
          </section>
          <div className={styles.dividerCard}>
            <div className={styles.line}></div>
          </div>
          <section className={styles.accomodation}>
            <FoodPreferences
              selectedFood={selectedFood}
              setSelectedFood={setSelectedFood}
              setIsFormUpdated={setIsFormUpdated}
            />
          </section>
          <div className={styles.dividerCard}>
            <div className={styles.line}></div>
          </div>
          <section className={styles.accomodation}>
            <Accommodations
              selectedAccomodation={selectedAccomodation}
              setSelectedAccomodation={setSelectedAccomodation}
              setIsFormUpdated={setIsFormUpdated}
            />
          </section>
          <div className={styles.dividerCard}>
            <div className={styles.line}></div>
          </div>
          <section className={styles.accomodation}>
            <Experience
              selectedActivities={selectedActivities}
              setSelectedActivities={setSelectedActivities}
              setIsFormUpdated={setIsFormUpdated}
            />
          </section>
          <div className={styles.dividerCard}>
            <div className={styles.line}></div>
          </div>

          {/* <section className={styles.climate}>
            <Climate
              update={update}
              setUpdate={setUpdate}
              climate={climate}
              setClimate={setClimate}
              population={population}
              setPopulation={setPopulation}
            />
          </section> */}
          {/* <div className={styles.dividerCard}>
            <div className={styles.line}></div>
          </div> */}
          <section className={styles.saveCard}>
            <Button
              variant={ButtonVariants.Contained}
              size={ButtonSizes.Small}
              className={styles.skipBtn}
              // onClick={handleSkip}
              onClick={handleClickOpen}>
              Cancel
            </Button>
            <Button
              variant={ButtonVariants.Contained}
              size={ButtonSizes.Small}
              className={styles.saveBtn}
              onClick={
                prefId === ""
                  ? handleAddUserPreferences
                  : handleUpdateUserPreference
              }>
              Save Changes
            </Button>
            {/* {prefId === "" && (
              <Button
                variant={ButtonVariants.Contained}
                size={ButtonSizes.Small}
                className={styles.skipBtn}
                onClick={handleSkip}>
                Skip
              </Button>
            )} */}
          </section>
        </div>
      )}
    </div>
  );
}

export default PreviewPreference;
