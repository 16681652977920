import React from "react";
import CustomAccordion from "../../component/reusableComponent/customAccordion/CustomAccordion";

export default function Test() {
  const arr = [
    {
      heading: "Heading 1",
      desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Doloribus cupiditate rerum aperiam! Modi pariatur odit consequuntur inventore placeat a ex.",
    },
    {
      heading: "Heading 2",
      desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Doloribus cupiditate rerum aperiam! Modi pariatur odit consequuntur inventore placeat a ex.",
    },
    {
      heading: "Heading 3",
      desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Doloribus cupiditate rerum aperiam! Modi pariatur odit consequuntur inventore placeat a ex.",
    },
    {
      heading: "Heading 4",
      desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Doloribus cupiditate rerum aperiam! Modi pariatur odit consequuntur inventore placeat a ex.",
    },
  ];
  return (
    <div>
     
      {arr.map((ele: any) => (
        <CustomAccordion
          headingComponent={<p>{ele.heading}</p>}
          bodyComponent={<p>{ele.desc}</p>}
        />
      ))}
          
          {arr.map((ele: any) => (
              <CustomAccordion
                  borderBottom={true}
          headingComponent={<p>{ele.heading}</p>}
          bodyComponent={<p>{ele.desc}</p>}
        />
      ))}
    </div>
  );
}
