export const ENVIRONMENT = {
  staging: {
    APP_URL: "https://app-beta.zenvoya.ai/",
    ENDPOINT: "https://api-staging.zenvoya.ai",
    azure: {
      // AZURE_API_KEY: "ba1e8ca487954c73bb1a6caa7ff79f78",
      // AZURE_URL: "https://theoptimistlabsopenai.openai.azure.com/openai/deployments/azureOpneai/chat/completions?api-version=2023-03-15-preview",
      AZURE_API_KEY: "d3253f97c7f84f0492c6017563106976",
      AZURE_URL:
        "https://azureopenaicanadeast.openai.azure.com/openai/deployments/gpt-4-canada-east/chat/completions?api-version=2023-03-15-preview",
    },
    GOOGLE_ANALYTICS_MEASUREMENT_ID: "G-JV76FH2EB6",
    // GOOGLE_ANALYTICS_MEASUREMENT_ID: "G-JV76FH2EB6",
    GOOGLE_MAP_API_KEY: "AIzaSyDwxGLXovmVwcR1lq_18claE3bu5lc3Y9w",
    S3_BUCKET_CONFIG: {
      AWS_ACCESS_KEY_ID_ENV: "AKIA6ODU2Y3LWIVRKWPD",
      AWS_SECRET_ACCESS_KEY_ENV: "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",
      REGION: "us-east-1",
      BUCKET_NAME: "zenvoya-uploads-dev-test",
    },
  },
  dev: {
    APP_URL: "https://app-dev.zenvoya.ai/",
    ENDPOINT: "https://api-dev.zenvoya.ai",
    azure: {
      // AZURE_API_KEY: "ba1e8ca487954c73bb1a6caa7ff79f78",
      // AZURE_URL: "https://theoptimistlabsopenai.openai.azure.com/openai/deployments/azureOpneai/chat/completions?api-version=2023-03-15-preview",
      AZURE_API_KEY: "d3253f97c7f84f0492c6017563106976",
      AZURE_URL:
        "https://azureopenaicanadeast.openai.azure.com/openai/deployments/gpt-4-canada-east/chat/completions?api-version=2023-03-15-preview",
    },
    GOOGLE_ANALYTICS_MEASUREMENT_ID: "G-BQSZGSMTNF",
    // GOOGLE_ANALYTICS_MEASUREMENT_ID: "G-8E95BH5C18",
    GOOGLE_MAP_API_KEY: "AIzaSyDwxGLXovmVwcR1lq_18claE3bu5lc3Y9w",
    S3_BUCKET_CONFIG: {
      AWS_ACCESS_KEY_ID_ENV: "AKIA6ODU2Y3LWIVRKWPD",
      AWS_SECRET_ACCESS_KEY_ENV: "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",
      REGION: "us-east-1",
      BUCKET_NAME: "zenvoya-uploads-dev-test",
    },
  },
  test: {
    APP_URL: "https://app-prompt.zenvoya.ai/",
    ENDPOINT: "https://api-test.zenvoya.ai",
    azure: {
      // AZURE_API_KEY: "ba1e8ca487954c73bb1a6caa7ff79f78",
      // AZURE_URL: "https://theoptimistlabsopenai.openai.azure.com/openai/deployments/azureOpneai/chat/completions?api-version=2023-03-15-preview",
      AZURE_API_KEY: "d3253f97c7f84f0492c6017563106976",
      AZURE_URL:
        "https://azureopenaicanadeast.openai.azure.com/openai/deployments/gpt-4-canada-east/chat/completions?api-version=2023-03-15-preview",
    },
    GOOGLE_ANALYTICS_MEASUREMENT_ID: "G-JV76FH2EB6",
    // GOOGLE_ANALYTICS_MEASUREMENT_ID: "G-JV76FH2EB6",
    GOOGLE_MAP_API_KEY: "AIzaSyDwxGLXovmVwcR1lq_18claE3bu5lc3Y9w",
    S3_BUCKET_CONFIG: {
      AWS_ACCESS_KEY_ID_ENV: "AKIA6ODU2Y3LWIVRKWPD",
      AWS_SECRET_ACCESS_KEY_ENV: "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",
      REGION: "us-east-1",
      BUCKET_NAME: "zenvoya-uploads-dev-test",
    },
  },
  production: {
    APP_URL: "https://app.zenvoya.ai",
    ENDPOINT: "https://api.zenvoya.ai",
    azure: {
      //   AZURE_API_KEY: "ba1e8ca487954c73bb1a6caa7ff79f78",
      //   AZURE_URL:
      //     "https://theoptimistlabsopenai.openai.azure.com/openai/deployments/azureOpneai/chat/completions?api-version=2023-03-15-preview",
      AZURE_API_KEY: "d3253f97c7f84f0492c6017563106976",
      AZURE_URL:
        "https://azureopenaicanadeast.openai.azure.com/openai/deployments/gpt-4-canada-east/chat/completions?api-version=2023-03-15-preview",
    },
    GOOGLE_ANALYTICS_MEASUREMENT_ID: "G-GF6BNC1L8X",
    GOOGLE_MAP_API_KEY: "AIzaSyDwxGLXovmVwcR1lq_18claE3bu5lc3Y9w",
    S3_BUCKET_CONFIG: {
      AWS_ACCESS_KEY_ID_ENV: "AKIA6ODU2Y3LWIVRKWPD",
      AWS_SECRET_ACCESS_KEY_ENV: "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",
      REGION: "us-east-1",
      BUCKET_NAME: "zenvoya-uploads-dev-test",
    },
  },
};

const {
  APP_URL,
  ENDPOINT,
  azure,
  GOOGLE_ANALYTICS_MEASUREMENT_ID,
  GOOGLE_MAP_API_KEY,
  S3_BUCKET_CONFIG,
} = ENVIRONMENT.staging || {};
const { AZURE_API_KEY, AZURE_URL } = azure || {};

export {
  APP_URL,
  ENDPOINT,
  AZURE_API_KEY,
  AZURE_URL,
  GOOGLE_ANALYTICS_MEASUREMENT_ID,
  GOOGLE_MAP_API_KEY,
  S3_BUCKET_CONFIG,
};
