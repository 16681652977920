export enum BudgetTypes {
  Economy = "Economy",
  MidRange = "Mid-range",
  Luxury = "Luxury",
}

export enum SORTBY {
  ASC = "ASC",
  DESC = "DESC",
}

export enum ButtonVariants {
  Text = "text",
  Contained = "contained",
  Outlined = "outlined",
}

export enum ButtonSizes {
  Small = "small",
  Medium = "medium",
  Large = "large",
}

export enum ChipVariants {
  Deletable = "Deletable",
  Clickable = "Clickable",
}

export enum EnvType {
  DEV = "dev",
  STAGING = "staging",
  PRODUCTION = "production",
}

export enum ScreenTypes {
  SingleItinerary = "singleItinerary",
  Reorder = "reorder",
  ItineraryInfo = "itineraryInfo",
  Adjust = "adjust",
  Edit = "edit",
  Preference = "preference",
  EmptyPage = "emptyPage",
  SavedItinerary = "savedItinerary",
}

export enum localStorageName {
  TriptroveFrequentlyViewTrips = "_triptrove_frequently_view_trips",
  TripTroveUserDetails = "_trip_trove_user_details",
  UserInfo = "user_info",
  AllCategory = "_all_category",
  TripTroveAppConfig = "_trip_trove_app_config",
}

export enum LOGIN_MESSAG {
  Success = "",
}
