import toast from "react-hot-toast";

export async function apiFailedTryAgain( apiCall: any) {
    let count = 0;
    let response = await apiCall();
    if (response)
        return response
    while (!response) {
        response = await apiCall();
        if (response) {
            return response;
        }
        count++;
        if (count === 5) {
            toast.error("API failed please try again!");
            return null;
        }
      }
}