import React, { useEffect, useState } from "react";
import styles from "./multipleDestinationCard.module.scss";
import { iCityDetailsResult } from "../../../types";
import { arrowNextIcon, selectedIcon } from "../../../constant";
import { Skeleton } from "@mui/material";
import { currencyToNumber } from "../../../utils";

export default function MultipleDestinationCard({
  destinationList,
}: {
  destinationList: iCityDetailsResult[];
}) {
  const [timeOutFlag, setTimeOutFlag] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setTimeOutFlag(false);
    }, 2000);
    console.log("YES", destinationList);
  }, [destinationList]);
  const selected = destinationList[0]?.checkedFlag || false;
  return (
    <div className={styles.mainContainer}>
      <div className={styles.imageContainer}>
        {destinationList[destinationList.length - 1]?.imageURL ? (
          destinationList.map((ele: iCityDetailsResult) => (
            <img
              src={ele?.imageURL ? ele?.imageURL : "https://error.error"}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/destinationPlaceholder.png";
                currentTarget.style.objectFit = "cover";
                currentTarget.style.height = "100%";
                currentTarget.style.width = `${100 / destinationList.length}%`;
              }}
              style={{ width: `${100 / destinationList.length}%` }}
              className={styles.img}
              // src={ele.imageURL}
              alt="city Image"
            />
          ))
        ) : (
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={"100%"}
            height={"100%"}
          />
        )}
      </div>
      <div
        className={`
        ${styles.placeHeadingContainer}
        ${selected && styles.placeHeadingContainerSelected}
        `}
      >
        <div className={styles.textContainer}>
          <div
            className={`
            ${styles.cityNameContainer}
            ${selected && styles.cityNameContainerSelected}
            `}
            style={{
              top: destinationList.length > 1 ? "40%" : "35%"
            }}
          >
            {destinationList.length > 1 ? (
              destinationList.map((ele: iCityDetailsResult, index: number) => (
                <h1 className={styles.placeHeadingMob} key={index}>
                  {index === destinationList.length - 1 ? (
                    ele.cityName.split(",")[0]
                  ) : (
                    <span>{ele.cityName.split(",")[0]},&nbsp;</span>
                  )}
                </h1>
              ))
            ) : (
              <div className={styles.placeHeadingMobContainer}>
                <h1 className={styles.placeHeadingMob}>
                  {" "}
                  {destinationList[0]?.cityName.split(",")[0]}
                </h1>
                {selected && (
                  <h2>
                    {" "}
                    {
                      destinationList[0]?.cityName.split(",")[
                        destinationList[0]?.cityName.split(",").length - 1
                      ]
                    }
                  </h2>
                )}
              </div>
            )}
          </div>
          <h1
            className={`
            ${styles.priceText}
            ${selected && styles.priceTextSelected}
            `}
          >
            from $
            {currencyToNumber(
              destinationList[0]?.complete_itinerary_flights_cost?.value
            ) +
              currencyToNumber(
                destinationList[0]?.complete_itinerary_hotels_cost?.value
              )}
          </h1>
          {selected ? (
            <img
              className={styles.selectedIcon}
              src={selectedIcon}
              alt="selected"
            />
          ) : (
            <img
              className={styles.nextIcon}
              src={arrowNextIcon}
              alt="arrowNextIcon"
            />
          )}
        </div>
      </div>
    </div>
  );
}
