export const HomePageNav = {
  LANDING_PAGE: "LANDING_PAGE",
  STARTING_DESTINATION: "STARTING_DESTINATION",
  PREFERENCES: "PREFERENCES",
  SELECTED_PREFERENCES: "SELECTED_PREFERENCES",
} as const;

export const DialogSelected = {
  LOGIN: "LOGIN",
  CREATE_ACCOUNT: "CREATE_ACCOUNT",
  SAVE_ITINERARY: "SAVE_ITINERARY",
  SAVE_ACTIVITY: "SAVE_ACTIVITY",
  EDIT_PROMPT: "EDIT_PROMPT",
  EDIT_PREFER: "Edit_Prefer",
  EDIT_ACCOUNT: "Edit_Account",
  ADD_PREFER: "Add_Prefer",
  FORGET_PASS: "Forget_Pass",
} as const;

export const APP_CONFIG = {
  HOME_CONSTANT_1: "Home_section_3",
  HOME_CONSTANT_2: "Home_section_2",
  SHOW_MORE: "show_more",
  MODIFY_DESTINATION_LIST: "want_to_modify_destination_list",
  API_FAILURE_MESSAGE: {
    error: "api_response_bad"
  },
  NO_INTERNET_MESSAGE: {
    info: "no_internet_connection"
  },
  SAVE_ITINERARY_MESSAGE: {
    success: "toast_Itinerary_save"
  },
  LOGIN: "login",
  SIGN_UP: "Sign_up",
  API_RESPONSE_BAD: "api_response_bad",
  NO_INTERNET_CONNECTION: "no_internet_connection",
  TOAST_PROFILE_UPDATE: "toast_profile_update",
  TOAST_PROFILE_ADD: "toast_profile_add",
  TOAST_PREFERENCE_ADD: "toast_preference_add",
  TOAST_PREFERENCE_UPDATE: "toast_preference_update",
  TOAST_RESETPASSWORD_LINK: "toast_resetpassword_link"
} as const;

export const PROMPT = {
  SHOW_MORE: "Show 2 more cities",
  // SHOW_MORE: "Give only 2 more city or results or destination not more than 2"
} as const;

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
