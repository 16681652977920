import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import styles from "./avatar.module.scss";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";
import profileIcon from "../../../assets/profileIcon.svg";
import { Box } from "@mui/material";
import Login from "../../login/login";
import Profile from "../../profile/profile";
// import Login from "../../login/login";

export default function ProfileMenu(): JSX.Element {
  const state = React.useContext(StateContext);
  const { userInfo, userDetails }: iGlobalContext = state;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [isClicked, setIsClicked] = React.useState(false);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsClicked(!isClicked);
  };

  const handleButtonClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setIsClicked(!isClicked);
    handleClick(event);
  };

  console.log("userDetails:-", userDetails);

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <Tooltip title="Profile">
          <IconButton
            onClick={handleButtonClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            className={`${isClicked ? styles.activeIcon : styles.iconBtn}`}>
            {userDetails.name && (
              <span className={styles.userName}>
                {userDetails.name.length < 25
                  ? userDetails.name
                  : userDetails.name.slice(0, 24) + "..."}
              </span>
            )}

            <Avatar
              className={styles.avatar}
              src={
                userDetails.profile_pic ? userDetails.profile_pic : profileIcon
              }
              alt=""
            />
          </IconButton>
        </Tooltip>
      </div>
      <Menu
        className={styles.profileContainer}
        anchorEl={anchorEl}
        id="basic-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            boxShadow:
              "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
            mt: 0.5,
            mr: 2,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
        {userInfo.loggedIn && (
          <Box
            width={240}
            sx={{ pl: 3, pr: 3, pt: 1 }}
            className={styles.emptyCard}>
            <Profile onClick={handleClose} />
          </Box>
        )}

        <Box
          minWidth={140}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pt: 4,
            pb: 1,
          }}>
          <Login closeDrawer={() => handleClose()} />
        </Box>
      </Menu>
    </div>
  );
}
