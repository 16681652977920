import axios from "axios";
import { ENDPOINT } from "../constant/environment";

export async function logoutAPI(id: string) {
  const URL = `${ENDPOINT}/app/v1/auth/logout/?userId=${id}`;
  let config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
  };
  // let body = {
  //   id,
  // };
  try {
    // console.log(body);
    const response = await axios.post(URL, config);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}
