import React, { useEffect, useState } from "react";
import blueArrow from "../../assets/arrowLeftLine.svg";
import editIcon from "../../assets/editProfile.svg";
import styles from "./adjust.module.scss";
import { Button, TextareaAutosize, Tooltip } from "@mui/material";
import AdjustCart from "../../component/adjustTrip/adjustCart/adjustCart";
// import Budget from "../../component/adjustTrip/budget/budget";
// import FoodPreferences from "../../component/adjustTrip/foodPreferences/foodPreferences";
import { ButtonSizes, ButtonVariants } from "../../enums";
import DeletedCart from "../../component/adjustTrip/deletedCart/deletedCart";
import { useNavigate } from "react-router-dom";
import {
  API_FAILURE_MESSAGE,
  ROUTES,
  ReOrderData,
  cancelIcon,
  closeIcon,
} from "../../constant";
import { adjustTripTypes } from "../../types/adjust";
import PassangerInfo from "../../component/adjustTrip/passangerInfo/passangerInfo";
import { StateContext } from "../../context/globalContext/context";
import { iGlobalContext } from "../../context/globalContext/interface";
import { iCityDetailsResult } from "../../types";
import FoodPreferences from "../../component/adjustTrip/foodPreferences/foodPreferences";
import Budget from "../../component/adjustTrip/budget/budget";
import CustomInput from "../../component/reusableComponent/customInput/CustomInput";
import toast from "react-hot-toast";
import { planTripDetails } from "../../api/planTripDetails";
import { checkIfThereIsDestination, getConfigMessage } from "../../utils";
import OverlayWithCancelBtn from "../../component/reusableComponent/overlayWithCancelBtn/OverlayWithCancelBtn";
import { toastMessage } from "../../helpers/toast/toastMessage";
import { APP_CONFIG } from "../../constant/common";
let abortPlanTripDetails = new AbortController();

function AdjustTrip(): JSX.Element {
  const navigate = useNavigate();
  const [sections, setSections] = useState<iCityDetailsResult[]>([]);
  const [removedSections, setRemovedSections] = useState<iCityDetailsResult[]>(
    []
  );
  const globalContext = React.useContext(StateContext);
  const {
    selectedChatData,
    setSelectedChatData,
    setOpenOverlayScreen,
    setIsItinerarySavedFlag,
    setSharedItineraryId,
    isMobile,
    setScreen,
    appConfig,
  }: iGlobalContext = globalContext;
  const [tripName, setTripName] = useState(selectedChatData?.tripName || "");
  const [disableEditTripNameFlag, setDisableEditTripNameFlag] = useState(true);

  useEffect(() => {
    setSections(
      selectedChatData.destinationList.filter(
        (ele: iCityDetailsResult) => ele.checkedFlag
      )
    );
    setRemovedSections(
      selectedChatData.destinationList.filter(
        (ele: iCityDetailsResult) => !ele.checkedFlag
      )
    );
  }, []);

  const handleNav = () => {
    isMobile ? navigate(ROUTES.ItineraryDetails) : setScreen("itineraryInfo");
  };

  async function handleCreateMultiCityTrip(selectedMultiCityList: any) {
    if (selectedMultiCityList.length === 0) {
      toastMessage.info("Please select City");
      return;
    }
    const cityAndNoOfDays = selectedMultiCityList
      .filter((city: any) => city.checkedFlag)
      .map((city: iCityDetailsResult) => ({
        city_name: city.cityName,
        num_days: city.noOfDays,
      }));
    console.log("CITY LIST", cityAndNoOfDays);
    setOpenOverlayScreen(true);
    abortPlanTripDetails = new AbortController();
    const iteneryInput = {
      user_given_number_of_days: cityAndNoOfDays,
    };
    let response = await planTripDetails(
      abortPlanTripDetails,
      // selectedChatData?.fromCity || "",
      // String(selectedChatData.noOfDays) || "",
      // cityList
      selectedChatData.prompt,
      iteneryInput
    );
    // const response: any = await planTripDetails(
    //   abortPlanTripDetails,
    //   selectedChatData.fromCity || "",
    //   String(selectedChatData.noOfDays) || "",
    //   cityList
    // );
    setOpenOverlayScreen(false);
    console.log("YES HERE BEFORE", response);
    response = checkIfThereIsDestination(response);
    console.log("YES HERE AFTER", response);
    if (
      response?.statusCode !== 200 ||
      response?.data?.cities === undefined ||
      response?.data?.cities.length === 0
    ) {
      toast.error(getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error));
      console.log(API_FAILURE_MESSAGE);
      return;
    }
    // const detailsResponse = response.data.details;
    // console.log("DETAIL res", detailsResponse);
    // if (!detailsResponse) {
    //   toast.error(API_FAILURE_MESSAGE);
    //   return;
    // }
    let tempDestinationList = selectedMultiCityList.filter(
      (destinationListEle: iCityDetailsResult, index: number) =>
        destinationListEle.checkedFlag
    );
    tempDestinationList = selectedMultiCityList.map(
      (destinationListEle: iCityDetailsResult, index: number) => ({
        ...destinationListEle,
        tripDetails: destinationListEle.checkedFlag
          ? response?.data?.cities[index]?.tripDetails || []
          : [],
        // tripDetails: detailsResponse[index],
      })
    );
    console.log("YES HERE< TEMP", tempDestinationList);
    setSelectedChatData({
      ...selectedChatData,
      destinationList: tempDestinationList,
      tripName: tripName,
      // tripName: tempDestinationList.reduce(
      //   (acc: string, ele: iCityDetailsResult, index: number) => {
      //     if (index === selectedChatData?.destinationList.length - 1)
      //       return acc + ele.cityName.split(",")[0];
      //     else return acc + ele.cityName.split(",")[0] + ", ";
      //   },
      //   ""
      // ),
    });
    setIsItinerarySavedFlag(false);
    setSharedItineraryId("");
    handleNav();
  }

  async function handleSaveClick() {
    // setSelectedChatData({
    //   ...selectedChatData,
    //   tripName: tripName,
    // });

    await handleCreateMultiCityTrip(sections);
    handleNav();
  }

  return (
    <div className={styles.main}>
      <OverlayWithCancelBtn
        handleCancel={() => {
          abortPlanTripDetails.abort();
        }}
      />
      <section className={styles.head}>
        <div className={styles.titleCard}>
          <div className={styles.titleBox}>
            <Tooltip title="Back">
              <img
                src={blueArrow}
                alt=""
                className={styles.blueIcon}
                onClick={handleNav}
              />
            </Tooltip>
            <div className={styles.headerCard}>
              <TextareaAutosize
                // onFocus={handleFocus}
                // placeholder="Type something"
                value={tripName}
                onChange={(e) => setTripName(e.target.value)}
                className={styles.location}
                disabled={disableEditTripNameFlag}
                // maxRows={15}
                // onKeyDown={handleKeyDown}
              />

              <span className={styles.duration}>
                minimum recommended duration:{" "}
                <span className={styles.days}>
                  {selectedChatData?.destinationList?.reduce(
                    (acc: number, ele: iCityDetailsResult) =>
                      ele.checkedFlag ? acc + 2 : acc,
                    0
                  )}{" "}
                  days
                </span>
              </span>
            </div>
          </div>
        </div>
        <Tooltip
          title="Edit"
          onClick={() => setDisableEditTripNameFlag(!disableEditTripNameFlag)}>
          <img
            src={disableEditTripNameFlag ? editIcon : closeIcon}
            alt=""
            className={styles.editIcon}
          />
        </Tooltip>
      </section>
      <div className={styles.reOrderCard}>
        <span className={styles.reOrder}>reorder</span>
      </div>
      <section className={styles.adjustTrip}>
        <AdjustCart
          sections={sections}
          removedSections={removedSections}
          setSections={setSections}
          setRemovedSections={setRemovedSections}
        />
      </section>
      <div className={styles.passengerCard}>
        <Budget />
        <FoodPreferences />
        {/* <p className={styles.pass}>Passengers</p>
        <div className={styles.passBox}>
          <PassangerInfo name="Adult" />
        </div>
        <div className={styles.passBox}>
          <PassangerInfo name="Age 12-21" />
        </div>
        <div className={styles.passBox}>
          <PassangerInfo name="Adult 0-12" />
        </div> */}
      </div>
      <section className={styles.btnCard}>
        <Button
          variant={ButtonVariants.Contained}
          size={ButtonSizes.Small}
          className={styles.saveBtn}
          onClick={handleSaveClick}>
          Save Changes
        </Button>
        <Button
          variant={ButtonVariants.Outlined}
          size={ButtonSizes.Small}
          className={styles.cancelBtn}>
          Cancel
        </Button>
      </section>
      <section className={styles.budget}>
        <DeletedCart
          sections={sections}
          removedSections={removedSections}
          setSections={setSections}
          setRemovedSections={setRemovedSections}
        />
      </section>
    </div>
  );
}

export default AdjustTrip;
