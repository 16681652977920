import React from "react";
import styles from "./desk.module.scss";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";
import InputWithMic from "../../reusableComponent/inputWithMic/InputWithMic";
import { zenvoyaChatIcon, NEW_TRIP } from "../../../constant";
import { Button, Stack } from "@mui/material";
import ChatContent from "../../../routes/topDestination/chatContent/ChatContent";
import CardWithShowMore from "../../../routes/topDestination/cardWithShowMore/CardWithShowMore";
import SelectedTrip from "../../../routes/topDestination/selectedTrip/SelectedTrip";
import { iAppConfig, iChatData } from "../../../types";
import ReactGA from "react-ga4";
import Loader from "../../reusableComponent/loader/Loader";
import InitialChat from "../../desktopLandingPage/initialChat/initialChat";
import updatedNewTrip from "../../../assets/updatedNewTrip.png";
import { APP_CONFIG } from "../../../constant/common";
import { useTopDestinationContext } from "../../../context/topDestinationContext/TopDestinationContext";
import { iTopDestinationContext } from "../../../context/topDestinationContext/topDestinationContextInterface";

function DeskTopDestinations(): JSX.Element {
  // Context
  const state = React.useContext(StateContext);
  const {
    chatInputText,
    setChatInputText,
    chatData,
    enableMultiSelect,
    setenableMultiSelect,
    appConfig,
  }: iGlobalContext = state;

  const {
    handleCheckboxChange,
    handleShowMore,
    handleViewSingleTripDetails,
    showChatLoaderFlag,
    navigate,
    disableInput,
    handleSendMessage,
    handleCancel,
    // snackbarMessage,
    // openSnackbar,
    // handleCancelMultiTrip,
    // handleCreateTrip,
    desktopScrollContainerRef,
  }: iTopDestinationContext = useTopDestinationContext();

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.inputCard}>
          <div className={styles.contentDiv} ref={desktopScrollContainerRef}>
            <div className={styles.sectionContainer}>
              <div className={styles.systemChatIcon}>
                <img
                  className={styles.chatIcon}
                  alt="chat logo"
                  src={zenvoyaChatIcon}
                />
                <h3 className={styles.heading}>zenvoya</h3>
              </div>
              <div
                className={styles.constText}
                dangerouslySetInnerHTML={{
                  __html:
                    appConfig?.length > 0
                      ? appConfig?.filter(
                          (ele: iAppConfig) =>
                            ele.key === APP_CONFIG.HOME_CONSTANT_1
                        )[0]?.value
                      : "",
                }}
              />
            </div>
            {chatData.map((eleChatData: iChatData, index: number) => (
              <div key={index} className={styles.chatBox}>
                {!eleChatData?.singleSelectedDestination && (
                  <ChatContent
                    isUserMessage={eleChatData.isUserMessage}
                    message={eleChatData.message}
                    preText={eleChatData.preText}
                    isLoading={
                      eleChatData?.destinationList?.length > 0
                        ? false
                        : eleChatData.isLoading
                    }
                  />
                )}
                {!eleChatData?.singleSelectedDestination &&
                  eleChatData.destinationList.length > 0 && (
                    <CardWithShowMore
                      onCheckboxChange={handleCheckboxChange}
                      data={eleChatData}
                      enableMultiSelect={enableMultiSelect}
                      handleShowMore={() => handleShowMore(eleChatData)}
                      postText={eleChatData?.postText}
                    />
                  )}
                {eleChatData?.singleSelectedDestination && (
                  <SelectedTrip
                    chatData={eleChatData}
                    handleViewTripDetails={() =>
                      handleViewSingleTripDetails(eleChatData)
                    }
                  />
                )}
              </div>
            ))}
            {showChatLoaderFlag && (
              <Stack mx={"-10px"} sx={{ ml: 2 }}>
                <InitialChat showUser={false} message={chatInputText} />
              </Stack>
            )}
            {showChatLoaderFlag && <Loader />}
            <div className={styles.showMoreLikeIconContainer}>
              <Button
                onClick={() => {
                  ReactGA.event({
                    category: NEW_TRIP.CATEGORY,
                    action: NEW_TRIP.ACTION,
                    label: ` ${NEW_TRIP.LABEL}`,
                  });
                  setenableMultiSelect(false);
                  setTimeout(() => {
                    navigate("/");
                  }, 100);
                }}
                className={styles.button}>
                <img
                  src={updatedNewTrip}
                  alt="show more"
                  className={styles.buttonIcon}
                />
                NEW TRIP
              </Button>
            </div>
          </div>
          <div className={styles.searchCard}>
            <div className={styles.inputBox}>
              <InputWithMic
                disable={disableInput}
                onSend={handleSendMessage}
                onStopRecording={handleSendMessage}
                value={chatInputText}
                setValue={setChatInputText}
                handleCancel={handleCancel}
                disableSendBtn={chatInputText === "" ? true : false}
              />
            </div>
          </div>
          {/* <CustomSnackbar
            disableCreateTripBtn={snackbarMessage.count === 0}
            open={openSnackbar}
            handleClose={handleCancelMultiTrip}
            handleCreateTrip={() => {
              let tempChatData = {} as iChatData;
              chatData.forEach((ele: iChatData) => {
                if (ele.destinationList.length > 1) {
                  const selectedCity = ele.destinationList.reduce(
                    (acc: number, destListEle: iCityDetailsResult) => {
                      if (destListEle.checkedFlag) return acc + 1;
                      return acc;
                    },
                    0
                  );
                  if (selectedCity > 0) tempChatData = ele;
                }
              });
              handleCreateTrip(tempChatData);
            }}
            messageOne={snackbarMessage.messageOne}
            messageTwo={snackbarMessage.messageTwo}
          /> */}
        </div>
      </div>
    </div>
  );
}

export default DeskTopDestinations;
