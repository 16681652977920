import axios from "axios";
import { ENDPOINT } from "../constant/environment";

export async function getUserDetailsApi(userId: string) {
  const URL = `${ENDPOINT}/app/v1/users/${userId}`;
  let config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
  };
  try {
    const response = await axios.get(URL, config);
    return response.data.data;
  } catch (error: any) {
    console.error("ERROR", error);
    console.error("ERROR", error.message);
    return null;
  }
}
