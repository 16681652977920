import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import styles from "./customAccordion.module.scss";
import { expandIcon } from "../../../constant";

export default function CustomAccordion({
  headingComponent,
  bodyComponent,
  borderBottom = false,
  defaultExpanded = false,
}: {
  headingComponent: React.ReactNode;
  bodyComponent: React.ReactNode;
  borderBottom?: boolean;
  defaultExpanded?: boolean;
}) {
  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      className={styles.mainContainer}
    >
      <AccordionSummary
        expandIcon={<img src={expandIcon} className={styles.expandIcon} />}
        aria-controls="panel1-content"
        id="panel1-header"
        className={styles.summary}
        sx={{
          borderBottom: borderBottom ? "1px solid #cacaca" : "none",
          backgroundColor: borderBottom ? "#F4F4F4" : "transparent",
        }}
      >
        {headingComponent}
      </AccordionSummary>
      <AccordionDetails>{bodyComponent}</AccordionDetails>
    </Accordion>
  );
}
