import React, { useEffect, useState } from "react";
import styles from "./budgetEstimates.module.scss";
import fligtIcon from "../../../assets/budgetEstimateFlight.png";
import hotelIcon from "../../../assets/budgetEstimateHotel.png";
import carIcon from "../../../assets/budgetEstimateCar.png";
import { checkedIcon, ROUTES, unCheckedIcon } from "../../../constant";
import { useSessionStorage } from "../../../hooks/useSessionStorage";
import { StateContext } from "../../../context/globalContext/context";
import { iGlobalContext } from "../../../context/globalContext/interface";
import { iCityDetailsResult } from "../../../types";
import { useLocation } from "react-router-dom";

export default function BudgetEstimates({
  data,
  hotelsCost,
  flightsCost,
  noOfCitySelected = 1,
}: {
  data?: iCityDetailsResult;
  hotelsCost?: any;
  flightsCost?: any;
  noOfCitySelected?: number;
}) {
  const stateContext = React.useContext(StateContext);
  const { setSelectedChatData, selectedChatData }: iGlobalContext =
    stateContext;
  const location = useLocation();
  
  useEffect(() => {
    setHotelsCostState(hotelsCost);
    setFlightsCostState(flightsCost);
  }, [ ]);

  // useEffect(() => {
  //   setHotelsCostState(hotelsCost);
  //   setFlightsCostState(flightsCost);
  // }, [hotelsCost, flightsCost]);


  const [hotelsCostState, setHotelsCostState] = useState(hotelsCost);
  const [flightsCostState, setFlightsCostState] = useState(flightsCost);
  function handleCheckboxClick(id: number) {
    // if (
    //   location.pathname === ROUTES.SavedItineraryDetails ||
    //   location.pathname === "/shared"
    // )
    //   return;
    // console.log(id);
    // if (id === flightsCostState.id)
    //   setFlightsCostState({
    //     ...flightsCostState,
    //     checkedFlag: !flightsCostState.checkedFlag,
    //   });
    // if (id === hotelsCostState.id)
    //   setHotelsCostState({
    //     ...hotelsCostState,
    //     checkedFlag: !hotelsCostState.checkedFlag,
    //   });
  }

  useEffect(() => {
    setSelectedChatData({
      ...selectedChatData,
      destinationList: selectedChatData.destinationList.map(
        (ele: iCityDetailsResult) => ({
          ...ele,
          complete_itinerary_flights_cost: flightsCostState,
          complete_itinerary_hotels_cost: hotelsCostState,
        })
      ),
    });
    console.log(flightsCostState, hotelsCostState);
  }, [flightsCostState, hotelsCostState]);

  return (
    <div className={styles.mainContainer}>
      <h1 className={styles.headingText}>
        {/* {noOfCitySelected === 1 ? "Estimated Cost" : "Book this Itinerary"} */}
        Estimated Cost
      </h1>
      {data?.cityName && (
        <h1 className={styles.headingText2}>
          {data?.cityName.split(",")[0]} for &nbsp;
          {data?.tripDetails?.length === 1
            ? `${data?.tripDetails?.length} DAYS`
            : `${data?.tripDetails?.length} DAYS`}
        </h1>
      )}
      <Component
        imageUrl={fligtIcon}
        title="Flights"
        checked={flightsCostState?.checkedFlag ?? true}
        handleCheckboxClick={() => handleCheckboxClick(flightsCostState?.id)}
        desc={
          flightsCostState?.value
            ? `From ${flightsCostState?.value} per person`
            : "No details"
        }
      />
      <Component
        imageUrl={hotelIcon}
        title="Hotels"
        checked={hotelsCostState?.checkedFlag ?? true}
        handleCheckboxClick={() => handleCheckboxClick(hotelsCostState?.id)}
        desc={
          hotelsCostState?.value
            ? `From ${hotelsCostState?.value} per night`
            : "No details"
        }
      />
    </div>
  );
}

function Component({
  imageUrl,
  title,
  desc,
  checked,
  handleCheckboxClick,
}: {
  imageUrl: string;
  title: string;
  desc: string;
  checked: boolean;
  handleCheckboxClick: any;
}) {
  return (
    <div className={styles.componentContainer}>
      <div className={styles.leftContainer} onClick={handleCheckboxClick}>
        {/* <img
          src={checked ? checkedIcon : unCheckedIcon}
          alt=""
          className={styles.checkboxIcon}
          loading="eager"
        /> */}
        <img loading="eager" src={imageUrl} alt="" className={styles.image} />
        <h3
          style={{ opacity: checked ? 1 : 0.5 }}
          className={styles.rightContainerText}
        >
          {title}
        </h3>
      </div>
      <h1
        style={{ opacity: checked ? 1 : 0.5 }}
        className={styles.rightContainerText}
      >
        {desc}
      </h1>
    </div>
  );
}
