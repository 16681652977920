import axios from "axios";
import { ENDPOINT } from "../constant/environment";

export async function createUserAPI(
  email: string,
  userName: string,
  password: string,
  phone: string,
) {
  const URL = `${ENDPOINT}/app/v1/users`;
  let config = {
    headers: {
     "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
  };
  let body = {
    email: email,
    userName: userName,
    password: password,
    phone: phone,
  };
  try {
    console.log(body);
    const response = await axios.post(URL, body, config);
    console.log(response);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}
