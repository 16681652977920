import * as React from "react";
import { Button, Stack } from "@mui/material";
import { StateContext } from "../../../context/globalContext/context";
import styles from "./header.module.scss";
import { iGlobalContext } from "../../../context/globalContext/interface";
import CustomSideBar from "../../customSideBar/customSideBar";

export default function Header() {
  const state = React.useContext(StateContext);
  const {}: iGlobalContext = state;

  return (
    <Stack className={styles.mainContainer}>
      <CustomSideBar />
    </Stack>
  );
}
