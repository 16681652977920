import React, { useContext, useEffect, useState } from "react";
import { Stack, Button } from "@mui/material";
import { StateContext } from "../../context/globalContext/context";
import { useNavigate } from "react-router-dom";
import { RWebShare } from "react-web-share";
import { checkIfUserIsLoggedIn } from "../../utils/loginAndLocalStorageutils";
import BackButtonHandler from "../../component/BackButtonHandler";
import { APP_URL } from "../../constant/environment";
import styles from "./itineraryDetails.module.scss";
import { iGlobalContext } from "../../context/globalContext/interface";
import ReactGA from "react-ga4";
import { DialogSelected } from "../../constant/common";
import CityTabs from "./cityTabs/CityTabs";
import {
  backIcon,
  buyIcon,
  closeWhiteIcon,
  editIcon,
  ROUTES,
  saveIcon,
  shareIcon,
} from "../../constant";
import { iChatData, iCityDetailsResult } from "../../types";
import { scrollToTop } from "../../utils";
import Loader from "../../component/reusableComponent/loader/Loader";
import { ScreenTypes } from "../../enums";
import { toastMessage } from "../../helpers/toast/toastMessage";
import { v4 } from "uuid";
import InputWithMic from "../../component/reusableComponent/inputWithMic/InputWithMic";

export default function ItineraryDetails() {
  const navigate = useNavigate();
  const state = useContext(StateContext);
  const {
    setSaveScreenState,
    setLoginCreateAccountState,
    setNav,
    setIsLoggedIn,
    setMenuOpenState,
    tripForNDays,
    setTripForNDays,
    singleItineraryDetails,
    setExtraMeta,
    extraMeta,
    sharedItineraryId,
    setSharedItineraryId,
    setUserDetails,
    setDataStream,
    query,
    setShareClick,
    isItinerarySavedFlag,
    setIsItinerarySavedFlag,
    setSelectedChatData,
    chatData,
    selectedChatData,
    scrollContainerRef,
    setScreen,
    isMobile,
    screen,
    setChatData,
    userInfo,
    setUserInfo,
    setModifyItineraryPrompt,
  }: iGlobalContext = state;
  // Axios controller to cancel API call
  const controller = new AbortController();
  const controllerAbortUpdateTrip = new AbortController();
  React.useEffect(() => {
    scrollToTop(scrollContainerRef);
    setNav("");
    window.scrollTo(0, 0);
    setTripForNDays([]);
    setDataStream("");
    const localPlanTrip = window.sessionStorage.getItem("_extra_meta");
    if (localPlanTrip !== null) {
      setTripForNDays(JSON.parse(localPlanTrip).planTripArray);
    }
    if (!userInfo.loggedIn) checkIfUserIsLoggedIn(setUserInfo);
    return () => {
      controllerAbortUpdateTrip.abort();
      controller.abort();
      setDataStream("");
    };
  }, []);

  React.useEffect(() => {
    console.log("Shared ID", sharedItineraryId);
    if (sharedItineraryId !== "") setIsItinerarySavedFlag(true);
    else {
      setIsItinerarySavedFlag(false);
    }
  }, [sharedItineraryId]);

  // Save Itinerary
  function handleSaveItinerary() {
    setExtraMeta({
      ...extraMeta,
      planTripArray: tripForNDays,
      query: query,
    });

    setMenuOpenState(false);
    setSaveScreenState(true);
    if (userInfo.loggedIn)
      setLoginCreateAccountState({
        flag: true,
        page: DialogSelected.SAVE_ITINERARY,
      });
    else setLoginCreateAccountState({ flag: true, page: DialogSelected.LOGIN });
  }

  // Onclick function to handle Back button click
  function handleBackButton() {
    if (screen === ScreenTypes.SingleItinerary) {
      setScreen("");
      return;
    }

    if (isMobile) {
      navigate(ROUTES.TopDestination);
    } else {
      setScreen(ScreenTypes.Reorder);
    }
  }

  async function handleShare() {
    if (!isItinerarySavedFlag) {
      setShareClick(true);
      setTimeout(() => {
        handleSaveItinerary();
      }, 1000);
      toastMessage.info("Please save the Itinerary before sharing!");
    }
  }

  const [noOfCitySelected, setNoOfCitySelected] = useState(1);
  useEffect(() => {
    setNoOfCitySelected(
      selectedChatData?.destinationList?.reduce(
        (acc: number, destListEle: iCityDetailsResult) => {
          if (destListEle.checkedFlag) return acc + 1;
          else return acc;
        },
        0
      )
    );
  }, [selectedChatData]);

  function handleOnShare() {
    console.log(sharedItineraryId, "PARAMS URL");
    ReactGA.event({
      category: "Button Click",
      action: `Click on Share Trip Details`,
      label: `User is sharing the itinerary ${APP_URL}/shared/${sharedItineraryId}`,
    });
  }

  const [tempSelectedChatData, setTempSelectedChatData] = useState(
    selectedChatData?.destinationList?.filter(
      (ele: iCityDetailsResult) => ele.checkedFlag
    )
  );
  useEffect(() => {
    setTempSelectedChatData(
      selectedChatData?.destinationList?.filter(
        (ele: iCityDetailsResult) => ele.checkedFlag
      )
    );
    console.log(selectedChatData);
  }, [selectedChatData]);

  function handleEdit() {
    if (isMobile) navigate(ROUTES.TopDestination);
    setModifyItineraryPrompt(
      `Modify the itinerary for ${selectedChatData.tripName}`
    );
    setChatData((prev: iChatData[]) => [
      ...prev,
      {
        id: v4(),
        message: `Modify the itinerary for ${selectedChatData.tripName}`,
        isUserMessage: true,
        prompt: selectedChatData?.prompt,
        isLoading: false,
        destinationList: [],
        singleSelectedDestination: false,
      },
      {
        id: v4(),
        message: "What would you like to change!",
        prompt: selectedChatData?.prompt,
        isUserMessage: false,
        isLoading: false,
        destinationList: [],
        singleSelectedDestination: false,
      },
    ]);
  }

  /**
   * chat input
   */
  const [inputValue, setInputValue] = useState("");

  if (JSON.stringify(selectedChatData) === "{}")
    return (
      <Stack mt={5}>
        <Loader />
      </Stack>
    );

  const handleNav = () => {
    isMobile ? navigate(ROUTES.AdjustTrip) : setScreen(ScreenTypes.Adjust);
  };

  return (
    <div className={styles.mainContainer}>
      <BackButtonHandler onBack={handleBackButton} />
      {/* Save and Share Button */}
      <div className={styles.shareSaveAdjustContainer}>
        {noOfCitySelected > 1 &&
          !selectedChatData.singleSelectedDestination && (
            <Button
              onClick={handleNav}
              className={`${styles.button} ${styles.adjustButton}`}
            >
              <img className={styles.iconButton} src={editIcon} alt="share" />
              ADJUST
            </Button>
          )}
      </div>

      {/* Itinerary Details */}
      <div className={styles.stickyHeading}>
        <div className={styles.closeBtnContainer}>
          <Button
            className={`${styles.columnAlignButton}`}
            onClick={handleBackButton}
          >
            <img
              className={styles.iconButton}
              src={backIcon}
              alt="Close"
            />
            <p>BACK</p>
          </Button>
        </div>
        <div className={styles.buttonContainer}>
          {/* <Button className={`${styles.rowAlignButton}`} onClick={handleEdit}>
            <img className={styles.iconButton} src={editIcon} alt="edit" />
            EDIT
          </Button> */}
          {isItinerarySavedFlag ? (
            <RWebShare
              data={{
                url: `${APP_URL}shared/${sharedItineraryId}`,
                title: "Zenvoya",
              }}
              onClick={handleOnShare}
            >
              <Button
                className={`${styles.rowAlignButton}`}
                onClick={handleShare}
              >
                <img
                  className={styles.iconButton}
                  src={shareIcon}
                  alt="share"
                />
                SHARE
              </Button>
            </RWebShare>
          ) : (
            <Button
              className={`${styles.rowAlignButton}`}
              onClick={handleShare}
            >
              <img className={styles.iconButton} src={shareIcon} alt="share" />
              SHARE
            </Button>
          )}
          <Button
            onClick={handleSaveItinerary}
            className={`${styles.rowAlignButton} ${styles.saveBtn}`}
          >
            <img className={styles.iconButton} src={saveIcon} alt="save" />
            SAVE
          </Button>
          <Button className={`${styles.rowAlignButton}`}>
            <img className={styles.iconButton} src={buyIcon} alt="buy" />
            Book
          </Button>
        </div>
      </div>

      {/* <div className={styles.headingContainer}>
        {noOfCitySelected === 1 && tempSelectedChatData?.length > 0 && (
          <div className={styles.headingText}>
            <h1>{tempSelectedChatData[0]?.cityName.split(",")[0]}</h1>
            <h2>
              {
                tempSelectedChatData[0]?.cityName.split(",")[
                  tempSelectedChatData[0]?.cityName.split(",").length - 1
                ]
              }
            </h2>
          </div>
        )}
      </div> */}

      {/* Tab container */}
      <CityTabs destinationList={tempSelectedChatData} />

{/*       <div className={styles.inputContainer}>
        <InputWithMic
          disable={false}
          onSend={() => {}}
          onStopRecording={() => {}}
          value={inputValue}
          setValue={setInputValue}
          handleCancel={() => {}}
          disableSendBtn={false}
        />
      </div> */}
    </div>
  );
}
//  onSend={() => handleSendMessage(chatInputText)}
//               onStopRecording={() => handleSendMessage(chatInputText)}
//               value={chatInputText}
//               setValue={setChatInputText}
//               handleCancel={handleCancel}
//               disableSendBtn={chatInputText === "" ? true : false} 
