import styles from "./promopt.module.scss";
import copyIcon from "../../assets/copyIcon.svg";
import { toastMessage } from "../../helpers/toast/toastMessage";
import { DataPrompts } from "./prompt";

function Prompts(): JSX.Element {
  const handleCopy = () => {
    const cardTitleElement = document.querySelector(
      `.${styles.cartTitle}`
    ) as HTMLElement;
    const descriptionElement = document.querySelector(
      `.${styles.desc}`
    ) as HTMLElement;

    if (cardTitleElement && descriptionElement) {
      const cardTitle = cardTitleElement.innerText;
      const description = descriptionElement.innerText;
      const textToCopy = `${cardTitle} ${description}`;

      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          toastMessage.success("Text copied to clipboard");
        })
        .catch((err) => {
          toastMessage.error("Failed to copy text: ", err);
        });
    } else {
      console.error("Failed to copy the text");
    }
  };
  return (
    <div className={styles.main}>
      <p className={styles.title}>Here are some example prompts:</p>
      <section className={styles.container}>
        {DataPrompts.map((i, index) => (
          <div className={styles.contentCard}>
            <div className={styles.card} key={index}>
              <p className={styles.cartTitle}>{i.title}</p>
              <div className={styles.descCard}>
                <span className={styles.desc}>{i.desc}</span>
                <img
                  src={copyIcon}
                  alt=""
                  className={styles.copyIcon}
                  onClick={handleCopy}
                />
              </div>
            </div>
          </div>
        ))}
      </section>
    </div>
  );
}

export default Prompts;
