import axios from "axios";
import { ENDPOINT } from "../constant/environment";

export async function editUserAPI(
  id: string,
  name: string,
  email: string,
  phone: string,
  profile_pic: string,
) {
  const URL = `${ENDPOINT}/app/v1/users`;
  let config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
  };
  let body = {
    id,
    name,
    email,
    phone,
    profile_pic,
  };
  try {
    const response = await axios.put(URL, body, config);
    return response.data.statusCode;
  } catch (error) {
    console.log(error);
  }
}
