import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { StateProvider } from "./context/globalContext/context";
import { Toaster } from "react-hot-toast";
import ContextProvider from "./context";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <BrowserRouter>
    <ContextProvider>
      <Toaster position="top-center" reverseOrder={false} />
      <App />
    </ContextProvider>
  </BrowserRouter>
);
