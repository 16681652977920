import axios from "axios";
import { ENDPOINT } from "../constant/environment";

export async function resetPassworAPI(id: string, newPassword: string) {
  const URL = `${ENDPOINT}/app/v1/users/password`;
  let config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
  };
  let body = {
    id,
    newPassword,
  };
  try {
    // console.log(body)
    const response = await axios.post(URL, body, config);
    console.log(response);
    return response.data.message;
  } catch (error) {
    console.log(error);
  }
}

export async function sendTokenAPI(id: string) {
  const URL = `${ENDPOINT}/app/v1/users/passwordlink`;
  let config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
  };
  let body = {
    email: id,
  };
  try {
    console.log(body);
    const response = await axios.post(URL, body, config);
    console.log(response);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getUserDetailByTokenAPI(token: string) {
  const URL = `${ENDPOINT}/app/v1/users/detailBytoken/?token=${token}`;

  try {
    const response = await axios.get(URL);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}
