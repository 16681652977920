import React from "react";
import styles from "./chatContent.module.scss";
import { zenvoyaChatIcon, profileIcon } from "../../../constant";
import Loader from "../../../component/reusableComponent/loader/Loader";
import Markdown from "react-markdown";

export default function ChatContent({
  message,
  isUserMessage,
  isLoading = false,
  preText,
}: {
  message: string;
  isUserMessage: boolean;
  isLoading?: boolean;
  preText: any;
}) {
  return (
    <div className={styles.container}>
      <div className={styles.systemChatIcon}>
        <img
          className={styles.chatIcon}
          alt="chat logo"
          src={isUserMessage ? profileIcon : zenvoyaChatIcon}
        />
        <h3 className={styles.heading}>{isUserMessage ? "YOU" : "zenvoya"}</h3>
      </div>

      {isLoading && <Loader />}
      <Markdown
        unwrapDisallowed
        className={styles.markdown}>
        {!isLoading && message !== "" ? message : preText}
      </Markdown>
    </div>
  );
}
