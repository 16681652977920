import styles from "./network.module.scss";

const NetworkError = ({ onRefresh, message }: any) => {
  return (
    <div className={styles.main}>
      <h2 className={styles.title}>{message}</h2>
      <button className={styles.btn} onClick={onRefresh}>
        Refresh
      </button>
    </div>
  );
};
export default NetworkError;
