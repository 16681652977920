import React, { useEffect, useState } from "react";
import styles from "./destinationCard.module.scss";
import { Circles } from "react-loader-spinner";
import { arrowNextIcon, selectedIcon } from "../../../constant";
import Loader from "../loader/Loader";
import { Skeleton } from "@mui/material";

export default function DestinationCard({
  key,
  destinationData,
  onClick,
  className = {},
  width = "",
  enableMultiSelect = false,
  onCheckboxChange = () => {},
  selectable = true,
  marginTop = "10px",
}: {
  key?: string | number;
  destinationData?: any;
  onClick?: any;
  className?: any;
  width?: string;
  enableMultiSelect?: boolean;
  onCheckboxChange?: any;
  selectable?: boolean;
  marginTop?: string;
}) {
  const [timeOutFlag, setTimeOutFlag] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setTimeOutFlag(false);
    }, 2000);
    // console.log("YES", destinationData)
  }, [destinationData]);
  const selected = destinationData?.checkedFlag && selectable;

  return (
    <label>
      <input
        type="checkbox"
        style={{ display: "none" }}
        id={String(key)}
        checked={destinationData?.checkedFlag && selectable}
        onChange={onCheckboxChange}
      />
      <div
        className={`${styles.mainContainer} ${className}`}
        key={key}
        onClick={onClick}
        style={{ width: width, marginTop: marginTop }}
      >
        <div className={styles.imageContainer}>
          {destinationData?.imageURL ? (
            <img
              src={
                destinationData?.imageURL
                  ? destinationData?.imageURL
                  : "https://error.error"
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/destinationPlaceholder.png";
                currentTarget.style.objectFit = "cover";
                currentTarget.style.height = "100%";
                currentTarget.style.width = "100%";
              }}
              alt=""
              className={styles.image}
            />
          ) : (
            <div className={styles.placeholderImageContainer}>
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={"100%"}
                height={"100%"}
              />
            </div>
          )}
        </div>

        {/* {destinationData?.checkedFlag && selectable ? (
          <div className={styles.selectedBox}>
            <div className={styles.overlay}></div>
            <img
              className={styles.selectedIcon}
              src={selectedIcon}
              alt="selected"
            />
          </div>
        ) : (
          enableMultiSelect && <div className={styles.selectBox}></div>
        )} */}

        <div
          className={`
            ${styles.placeHeadingContainer} 
            ${selected && styles.placeHeadingContainerSelected}
          `}
        >
          <div className={styles.textContainer}>
            {selected ? (
              <h1
                className={`${styles.placeHeadingMob} ${styles.placeHeadingMobSelected}`}
              >
                <span>{destinationData?.cityName.split(",")[0]}</span>
                <span className={styles.countryName}>
                  {
                    destinationData?.cityName.split(",")[
                      destinationData?.cityName.split(",").length - 1
                    ]
                  }
                </span>
              </h1>
            ) : (
              <h1 className={styles.placeHeadingMob}>
                {destinationData?.cityName?.length > 15
                  ? destinationData?.cityName.substring(0, 15) + "..."
                  : destinationData?.cityName}
              </h1>
            )}

            <h1
              className={`
              ${styles.priceText}
              ${selected && styles.priceTextSelected}
              `}
            >
              {destinationData?.averagePackagePriceForExperiencing ||
              destinationData?.minimum_cost_for_tour ? (
                `from ${
                  destinationData?.averagePackagePriceForExperiencing ||
                  destinationData?.minimum_cost_for_tour
                }`
              ) : (
                <span className={styles.hidden}>No Details</span>
              )}
            </h1>
          </div>
          {selected ? (
            <img
              className={styles.selectedIcon}
              src={selectedIcon}
              alt="selected"
            />
          ) : (
            <img
              className={styles.nextIcon}
              src={arrowNextIcon}
              alt="arrowNextIcon"
            />
          )}
        </div>
      </div>
    </label>
  );
}
