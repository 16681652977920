export const VIEW_RECOMMENDED_TRIPS = {
    CATEGORY: "View Details",
    ACTION: "View one of the Recommended Trip",
    LABEL: "User is viewing the recommended trip to ",
}

export const SENDING_CHAT_MESSAGE = {
    CATEGORY: "Button Click",
    ACTION: "Click on Send Message",
    LABEL: "User promt is: ",
}

export const SEE_MORE = {
    CATEGORY: "Button Click",
    ACTION: "Click on see more button",
    LABEL: "User wants to see more destination list"
}

export const VIEW_ITINERARY_DETAILS = {
    CATEGORY: "View Details",
    ACTION: "View one of the trip",
    LABEL: "User is viewing trip to "
}

export const SAVING_ITINERARY = {
    CATEGORY: "Button Click",
    ACTION: "Click on save itinerary button",
    LABEL: "User is saving itinerary to "
}

export const SHARING_ITINERARY = {
    CATEGORY: "Button Click",
    ACTION: "Click on shared itinerary button",
    LABEL: "User wants to shared the saved itinerary "
}

export const NEW_TRIP = {
    CATEGORY: "Button Click",
    ACTION: "Click on New Trip button",
    LABEL: "User wants to create a new trip"
}

export const LOGGING_IN = {
    CATEGORY: "Button Click",
    ACTION: "Click on log in",
    LABEL: "User is logging in"
}

export const CREATE_ACCOUNT = {
    CATEGORY: "Button Click",
    ACTION: "Click on create account",
    LABEL: "User is creating an account"
}

export const SELECTED_PREFERENCES = {
    CATEGORY: "Button Click",
    ACTION: "Click on save preferences button",
    LABEL: "User is saving their preferences "
}

export const VIEW_SAVE_HISTORY = {
    CATEGORY: "Click",
    ACTION: "Click on one of the save history",
    LABEL: "User is viewing the save hitory of "
}

export const VIEW_SAVED_ITINERARY = {
    CATEGORY: "Card Click",
    ACTION: "Click on one of the save itinerary",
    LABEL: "User wants to see the saved itinerary to "
}

export const DELETE_SAVED_ITINERARY = {
    CATEGORY: "Button Click",
    ACTION: "Click on delete button",
    LABEL: "User is deleting the saved itinerary to "
}

export const LOGGED_OUT = {
    CATEGORY: "Button Click",
    ACTION: "Click on Log out button",
    LABEL: "User is logging out"
}