import { format, getDaysInMonth, addMonths } from "date-fns";
import { v4 as uuidv4 } from "uuid";
interface ObjType {
  id: number;
  data: string;
  numOfDays: string;
  year: string;
  check: boolean;
  monthIndex?: number;
}
export function getCurrentMonthWithTheNextTweleMonthAPI() {
  const arrayOfMonths: ObjType[] = [];
  // Anytime
  arrayOfMonths.push({
    id: uuidv4(),
    numOfDays: "31",
    data: "Anytime",
    year: "",
    check: true,
  });
  // Current Month
  const currentMonth = new Date();
  console.log(" page MONTHS", currentMonth.getMonth());
  const cur =
    format(currentMonth, "MMMM yyyy") + " " + getDaysInMonth(currentMonth);
  arrayOfMonths.push(helper(cur, currentMonth.getMonth()));

  // Get the next 12 months with number of days
  for (let i = 1; i < 12; i++) {
    const nextMonth = addMonths(new Date(), i);
    const next =
      format(nextMonth, "MMMM yyyy") + " " + getDaysInMonth(nextMonth);
    // console.log(next);
    arrayOfMonths.push(helper(next, nextMonth.getMonth()));
  }
  return {
    title: "Departure",
    tag: arrayOfMonths,
  };
}

// helper function to extract month/numberOfDays/year
function helper(month: string, monthIndex: number) {
  const array = month.split(" ");
  // console.log(array)
  const tempObj: ObjType = {
    id: uuidv4(),
    data: array[0],
    numOfDays: array[2],
    year: array[1],
    check: false,
    monthIndex: monthIndex,
  };
  return tempObj;
}
