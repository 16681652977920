import { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import ItineraryDetails from "./itineraryDetails/ItineraryDetails";
import EditAccount from "./editAccount/EditAccount";
import ResetPasswordPage from "./resetPassword/ResetPasswordPage";
import ViewShareItinerary from "./viewSharedItineraryDetails/ViewShareItinerary";
import TopDestination from "./topDestination/TopDestination";
import NotFound from "../component/NotFound/NotFound";
import RecommendedTrips from "./recommendedTrips/RecommendedTrips";
import AdjustTrip from "./adjustTrip/adjustTrip";
import PreviewPreference from "./previewPreference/previewPreference";
import { ROUTES } from "../constant";
import ReorderMultiCityTrip from "./reorderMultipCityTrip/ReorderMultiCityTrip";
import SavedItineraryDetail from "./savedItineraryDetails/SavedItineraryDetail";
import { StateContext } from "../context/globalContext/context";
import { iGlobalContext } from "../context/globalContext/interface";
import ProtectedRoute from "./protectedRoute/ProtectedRoute";
import Test from "./test/Test";
import NewTrip from "./newTrip/newTrip";

export default function AppRoutes() {
  const context = useContext(StateContext);
  const { userInfo }: iGlobalContext = context;
  return (
    <Routes>
      <Route path={ROUTES.Home} element={<RecommendedTrips />} />
      <Route path={ROUTES.ItineraryDetails} element={<ItineraryDetails />} />
      <Route
        path={ROUTES.EditAccount}
        element={
          <ProtectedRoute
            isLoggedIn={userInfo.loggedIn}
            component={EditAccount}
          />
        }
      />
      <Route path="/reset-password/:id" element={<ResetPasswordPage />} />
      <Route path="/shared/:id" element={<ViewShareItinerary />} />
      <Route path={ROUTES.TopDestination} element={<TopDestination />} />
      <Route
        path={ROUTES.SavedItineraryDetails}
        element={<SavedItineraryDetail />}
      />

      <Route path={ROUTES.RecommendedTrips} element={<RecommendedTrips />} />

      <Route path={ROUTES.AdjustTrip} element={<AdjustTrip />} />
      <Route
        path={ROUTES.PreviewPreference}
        element={
          <ProtectedRoute
            isLoggedIn={userInfo.loggedIn}
            component={PreviewPreference}
          />
        }
      />
      <Route
        path={ROUTES.ReorderMultiCityTrip}
        element={<ReorderMultiCityTrip />}
      />

<Route
        path={"test"}
        element={<Test />}
      />
      <Route path={ROUTES.NewTrip} element={<NewTrip />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
