import { iChatData, iCityDetailsResult } from "../types";
import { getSelectedOptionsStringArray } from "./recentlyViewTripsItem";
import { v4 } from "uuid";

export function getSelectedOptionsStringArrayFromAllOptions(options: any) {
  if (!options) return "No data";
  let stringArr: any = [];
  for (let i = 0; i < options.length; i++) {
    const ele = options[i]?.tag;
    const selectedOption = getSelectedOptionsStringArray(ele);
    if (selectedOption !== "") stringArr.push(selectedOption);
  }
  let string = stringArr.join(", ");
  console.log("FREQ", stringArr);
  return string;
}

export function checkAndReturnSingularOrPluralWord(
  number: number,
  word: string
) {
  if (!number) return word;
  else if (number === 1) return number + " " + word;
  else return number + " " + word + "s";
}

// Helper function to join options of string
export function concatenateOptionsToString(array: any) {
  let temp = "";
  const tempArray = array.tag;
  for (let i = 0; i < tempArray.length; i++) {
    if (tempArray[i].check) {
      temp += tempArray[i].data + ", ";
    }
  }
  return temp.substring(0, temp.length - 2);
}

export function extractPlanTripPrompt(
  promptTemplate: string,
  city: string,
  climate: any,
  population: any,
  experiences: any,
  budget: any,
  noOfDays: number | string,
  accomodations: any,
  foodPreferences: any,
  selectedDestFlag: boolean
) {
  let climatePopulationPrompt = "";
  let accomodationsPrompt = "";
  let foodPreferencesPrompt = "";

  if (climate !== "")
    climatePopulationPrompt = ` The itinerary should include places with ${climate} in ${population} areas.`;
  if (selectedDestFlag) climatePopulationPrompt = "";
  if (accomodations !== "")
    accomodationsPrompt = `They would like to stay at (lodging) ${accomodations}.`;
  if (foodPreferences !== "")
    foodPreferencesPrompt = `They prefer ${foodPreferences} food.`;

  const replacements = {
    noOfDays,
    city,
    climatePopulationPrompt,
    accomodationsPrompt,
    foodPreferencesPrompt,
    budget,
    experiences,
  };
  const prompt = replaceWords(promptTemplate, replacements);
  console.log("PROMPT MODIFIED", replacements, prompt);
  return prompt;
}

function replaceWords(prompt: string, replacements: any) {
  const pattern = /<([^>]+)>/g;
  prompt = prompt.replace(pattern, (match, group) => {
    const replacement = replacements[group];
    return replacement ? replacement : "";
  });
  return prompt;
}

/**
 * check if there are cities or destination list
 */
export function checkIfThereIsDestination(response: any) {
  const element = [
    {
      start: "<recommendationsJsonStart>",
      end: "<recommendationsJsonEnd>",
      singleTrip: false,
    },
    {
      start: "<itineraryJsonStart>",
      end: "<itineraryJsonEnd>",
      singleTrip: true,
    },
  ];
  let results: any = {};

  let startIndex = 0;
  let endIndex = 0;

  for (let i = 0; i < element.length; i++) {
    const ele = element[i];
    if (response?.data?.result.indexOf(ele.start) > 0) {
      startIndex = response?.data?.result.indexOf(ele.start) + ele.start.length;
      endIndex = response?.data?.result.indexOf(ele.end);
      if (ele.singleTrip) {
        if (startIndex > 0 && endIndex > 0) {
          let subString = response?.data?.result.substring(
            startIndex,
            endIndex
          );
          results = JSON.parse(subString);
        }
        console.log("HERE IT IS RESULT REAL", results);
        if (JSON.stringify(results) !== "{}") {
          response = {
            ...response,
            data: {
              ...response.data,
              from: results?.from,
              // numberOfdays: results?.number_of_days,
              numberOfdays:
                results?.city_wise_itinerary?.reduce(
                  (acc: number, ele: any) => ele?.city_itinerary.length + acc,
                  0
                ) || null,
              preText: response?.data?.result.substring(
                0,
                startIndex - ele.start.length
              ),
              postText: response?.data?.result.substring(
                endIndex + ele.end.length,
                response?.data?.result.length
              ),
              singleCity: true,
              // cities:  [{
              //   cityName: results[0].City,
              //   averagePackagePriceForExperiencing: results[0]?.approximate_trip_cost,
              //   description: results[0]?.destination_description,
              //   tripDetails: results[0]?.details
              // }],
              cities: results?.city_wise_itinerary?.map((ele: any) => ({
                cityName: ele?.city_name + ", " + ele?.city_country,
                averagePackagePriceForExperiencing:
                  results[0]?.approximate_trip_cost,
                description: ele?.city_itinerary_description,
                tripDetails: ele?.city_itinerary,
                complete_itinerary_flights_cost: {
                  value: results?.complete_itinerary_flights_cost || "",
                  checkedFlag: true,
                  id: v4(),
                },
                complete_itinerary_hotels_cost: {
                  value: results?.complete_itinerary_hotels_cost || "",
                  checkedFlag: true,
                  id: v4(),
                },
              })),
              // cities:  [{
              //   cityName: results?.city_wise_itinerary[0]?.city_name + ", " + results?.city_wise_itinerary[0]?.city_country,
              //   averagePackagePriceForExperiencing: results[0]?.approximate_trip_cost,
              //   description: results?.city_wise_itinerary[0]?.city_itinerary_description,
              //   tripDetails: results?.city_wise_itinerary[0]?.city_itinerary
              // }],
              // city_wise_itinerary: results?.city_wise_itinerary
            },
          };
        }
      } else {
        if (startIndex > 0 && endIndex > 0) {
          let subString = response?.data?.result.substring(
            startIndex,
            endIndex
          );
          results = JSON.parse(subString);
        }
        console.log("HERE IT IS RESULT REAL", results);
        if (JSON.stringify(results) !== "{}") {
          response = {
            ...response,
            data: {
              ...response.data,
              preText: response?.data?.result.substring(
                0,
                startIndex - ele.start.length
              ),
              postText: response?.data?.result.substring(
                endIndex + ele.end.length,
                response?.data?.result.length
              ),
              from: results?.from,
              numberOfdays: results?.number_of_days,
              cities: results?.results?.map((ele: any) => ({
                cityName: ele.destination_name,
                averagePackagePriceForExperiencing: ele.approximate_trip_cost,
                description: ele.destination_description,
              })),
              singleCity: false,
            },
          };
        }
      }
    }
  }

  return response;
}

export function scrollToBottom(scrollContainerRef: any) {
  setTimeout(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        top: scrollContainerRef.current.scrollHeight,
        // behavior: 'smooth'
      });
    }
    window.scrollTo({
      top: document.documentElement.scrollHeight,
    });
  }, 10);
}

export function scrollToTop(scrollContainerRef: any) {
  setTimeout(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        top: 0,
        // behavior: 'smooth'
      });
    }
    window.scrollTo({
      top: 0,
    });
  }, 10);
}

export function getTripName(chatData: iChatData) {
  return chatData.destinationList.reduce(
    (acc: string, ele: iCityDetailsResult, index: number) => {
      if (index === chatData?.destinationList.length - 1)
        return acc + ele.cityName.split(",")[0];
      else return acc + ele.cityName.split(",")[0] + ", ";
    },
    ""
  );
}
