import { useRef, useContext, useEffect, useState } from "react";
import {
  ROUTES,
  SENDING_CHAT_MESSAGE,
  VIEW_RECOMMENDED_TRIPS,
  zenvoyaChatIcon,
  newTripLogo,
} from "../../constant";
import InputWithMic from "../../component/reusableComponent/inputWithMic/InputWithMic";
import { iGlobalContext } from "../../context/globalContext/interface";
import { StateContext } from "../../context/globalContext/context";
import { getFrequentlyViewTripsApi } from "../../api/getFrequentlyViewTrips";
import SpeechRecognition from "react-speech-recognition";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { chatInteractionAPI } from "../../api/chatInteractionAPI";
import { useSessionStorage } from "../../hooks/useSessionStorage";
import { v4 } from "uuid";
import { iAppConfig } from "../../types";
import { getItineraryImageURL } from "../../api/getItineraryImageURL";
import {
  checkIfThereIsDestination,
  getConfigMessage,
  scrollToBottom,
} from "../../utils";
import Loader from "../../component/reusableComponent/loader/Loader";
import { RESPONSE_DUMMY } from "../../constant/responseDummy";
import InitialChat from "../../component/desktopLandingPage/initialChat/initialChat";
import ReactGA from "react-ga4";
import { APP_CONFIG } from "../../constant/common";
import { toastMessage } from "../../helpers/toast/toastMessage";
import { localStorageName } from "../../enums";
import styles from "./new.module.scss";

function NewTrip(): JSX.Element {
  const context = useContext(StateContext);
  const {
    chatInputText,
    setChatInputText,
    setShowMoreFlag,
    setApiResponse,
    setOpenOverlayScreen,
    setChatData,
    setSharedItineraryId,
    setIsItinerarySavedFlag,
    setenableMultiSelect,
    scrollContainerRef,
    userDetails,
    setCallSaveHistoryAPIFlag,
    appConfig,
  }: iGlobalContext = context;
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [disableInput, setDisableInput] = useState<boolean>(false);
  const [temFreqViewTrips, setFreqViewTrips] = useSessionStorage(
    localStorageName.TriptroveFrequentlyViewTrips,
    []
  );
  const [index, setIndex] = useState({
    first: 0,
    last: 3,
  });
  useEffect(() => {
    setIndex({
      first: 0,
      last: temFreqViewTrips.length - 1,
    });
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: 5320,
      });
    }
  }, [temFreqViewTrips]);

  const [frequentlyViewTrips, setFrequentlyViewTrips] = useState([] as any[]);
  async function getFrequentlyViewTrips() {
    const response = await getFrequentlyViewTripsApi();
    if (response && response?.statusCode === 200) {
      setFreqViewTrips(response?.data);
      setFrequentlyViewTrips([
        ...response?.data,
        ...response?.data,
        ...response?.data,
        ...response?.data,
        ...response?.data,
        ...response?.data,
        ...response?.data,
      ]);
    } else {
    }
  }
  useEffect(() => {
    getFrequentlyViewTrips();
  }, []);

  function handleScrollLeft() {
    if (index.first > 0 && sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -190,
        behavior: "smooth",
      });
      setIndex({
        first: index.first - 1,
        last:
          (index.last - 1 + temFreqViewTrips.length) % temFreqViewTrips.length,
      });
    }
  }

  function handleScrollRight() {
    setFrequentlyViewTrips([
      ...frequentlyViewTrips,
      temFreqViewTrips[index.first],
    ]);
    setIndex({
      first: (index.first + 1) % temFreqViewTrips.length,
      last: (index.last + 1) % temFreqViewTrips.length,
    });
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: 190,
        behavior: "smooth",
      });
    }
  }

  const navigate = useNavigate();
  async function handleCreateItinerary() {
    await setTimeout(() => {
      console.log("");
    }, 1000000);
    scrollToBottom(scrollContainerRef);
    setCallSaveHistoryAPIFlag(false);

    setenableMultiSelect(false);
    if (chatInputText === "") {
      toastMessage.info("Please type your message!");
      return;
    }
    setOpenOverlayScreen(true);
    setDisableInput(true);
    SpeechRecognition.stopListening();
    setApiResponse([]);
    setShowMoreFlag(false);
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    let response = await chatInteractionAPI(
      userDetails?.id || "",
      chatInputText,
      [],
      abortController.signal
    );
    if (!response) {
      toast.error(
        getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      );
      setDisableInput(false);
      console.error("Please try again!");
      return;
    }
    setOpenOverlayScreen(false);
    setDisableInput(false);
    response = checkIfThereIsDestination(response);
    console.log("HERE IT IS", response.data);
    const citiesList = response?.data?.cities;
    console.log(citiesList);
    if (citiesList?.length > 0) {
      console.log("HERE IT IS RES", citiesList);
      const tempResponse = citiesList.map((ele: any) => ({
        ...ele,
        id: v4(),
        // checkedFlag: response?.data?.singleCity,
        checkFlag: false,
        locations: [],
      }));
      setChatData([
        {
          id: v4(),
          message: chatInputText,
          isUserMessage: true,
          prompt: [],
          isLoading: false,
          destinationList: [],
          singleSelectedDestination: false,
        },
        {
          fromCity: response?.data?.from,
          noOfDays: response?.data?.numberOfdays,
          id: v4(),
          message: "",
          preText: response?.data?.preText,
          postText: response?.data?.postText,
          prompt: response?.data?.messages,
          isUserMessage: false,
          isLoading: false,
          singleSelectedDestination: response?.data?.singleCity,
          destinationList: tempResponse,

          complete_itinerary_description:
            RESPONSE_DUMMY.complete_itinerary_description,
          city_wise_itinerary: RESPONSE_DUMMY.city_wise_itinerary,
        },
      ]);
      navigate(ROUTES.TopDestination);
      const currentItineraryList = tempResponse;
      for (let i = 0; i < currentItineraryList.length; i++) {
        const cityName = currentItineraryList[i].cityName;
        let imageRespone: any = await getItineraryImageURL(cityName);
        let count = 0;
        while (!imageRespone || !imageRespone?.data?.image) {
          // Limiting the number of API call to 10 times incase of 409 error
          count++;
          if (count === 5) break;
          // re calling the API
          imageRespone = await getItineraryImageURL(cityName);
        }
        if (imageRespone?.statusCode === 200) {
          currentItineraryList[i].imageURL = imageRespone.data.image;
        } else {
          currentItineraryList[i].imageURL = "https://error.error";
          console.log("RESPONSE TOP Error");
        }
      }
    } else {
      setChatData([
        {
          id: v4(),
          message: chatInputText,
          isUserMessage: true,
          prompt: [],
          isLoading: false,
          destinationList: [],
          singleSelectedDestination: false,
        },
        {
          id: v4(),
          message: response?.data?.result,
          prompt: response?.data?.messages,
          isUserMessage: false,
          isLoading: false,
          destinationList: [],
          singleSelectedDestination: false,
        },
      ]);
    }
    setCallSaveHistoryAPIFlag(true);
    setOpenOverlayScreen(false);
    setDisableInput(false);

    ReactGA.event({
      category: SENDING_CHAT_MESSAGE.CATEGORY,
      action: SENDING_CHAT_MESSAGE.ACTION,
      label: ` ${SENDING_CHAT_MESSAGE.LABEL}  ${chatInputText}`,
    });
    console.log(chatInputText);
    navigate(ROUTES.TopDestination);
  }

  function handleViewItineraryDetails(data: any) {
    setSharedItineraryId(`${data.id}` || "");
    setIsItinerarySavedFlag(true);
    ReactGA.event({
      category: VIEW_RECOMMENDED_TRIPS.CATEGORY,
      action: VIEW_RECOMMENDED_TRIPS.ACTION,
      label: ` ${VIEW_RECOMMENDED_TRIPS.LABEL}  ${data?.tripName}`,
    });
    navigate(ROUTES.SavedItineraryDetails);
  }

  const handleCancel = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      setOpenOverlayScreen(false);
      setChatInputText("");
      setDisableInput(false);
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.logoCard}>
          <img src={newTripLogo} alt="" className={styles.logo} />
        </div>
        <div className={styles.inputCard}>
          <div className={styles.inputContainer}>
            <div className={styles.contentContainer}>
              <div className={styles.sectionContainer}>
                <div className={styles.systemChatIcon}>
                  <img
                    className={styles.chatIcon}
                    alt="chat logo"
                    src={zenvoyaChatIcon}
                  />
                  <h3 className={styles.heading}>zenvoya</h3>
                </div>
                <div
                  className={styles.constText}
                  dangerouslySetInnerHTML={{
                    __html:
                      appConfig?.length > 0
                        ? appConfig?.filter(
                            (ele: iAppConfig) =>
                              ele.key === APP_CONFIG.HOME_CONSTANT_1
                          )[0]?.value
                        : "",
                  }}
                />
              </div>
              {disableInput && (
                <div className={styles.chatLoaderContainer}>
                  <div className={styles.chatLoaderSubContainer}>
                    <InitialChat message={chatInputText} />
                  </div>
                </div>
              )}
              {disableInput && <Loader />}
            </div>
            <InputWithMic
              onStopRecording={handleCreateItinerary}
              onSend={handleCreateItinerary}
              value={chatInputText}
              setValue={setChatInputText}
              disable={disableInput}
              handleCancel={handleCancel}
              setDisableInput={setDisableInput}
              disableSendBtn={chatInputText === "" ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewTrip;
