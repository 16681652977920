import axios from "axios";
import { ENDPOINT } from "../constant/environment";

export async function getItinerariesListAPI(userId: string) {
  const URL = `${ENDPOINT}/app/v1/users/itineraries/?userId=${userId}`;

  let config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
  };

  try {
    const response = await axios.get(URL, config);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}
