import React, { useRef, useState } from "react";
import { Stack, Button, TextField, IconButton } from "@mui/material";
import cross from "../../../assets/white-close-line.svg";
import { StateContext } from "../../../context/globalContext/context";
import { saveItineraryDetailsAPI } from "../../../api/saveItineraryDetails";
import { APP_URL } from "../../../constant/environment";
import { RWebShare } from "react-web-share";
import { iGlobalContext } from "../../../context/globalContext/interface";
import ReactGA from "react-ga4";
import { SAVING_ITINERARY } from "../../../constant";
import toast from "react-hot-toast";
import { toastMessage } from "../../../helpers/toast/toastMessage";
import { getConfigMessage } from "../../../utils";
import { APP_CONFIG } from "../../../constant/common";

export default function SaveItineraryDialog({ props }: any) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    console.log(extraMeta);
  }, []);

  const { handleCancel } = props;

  const state = React.useContext(StateContext);
  const {
    setLoginCreateAccountState,
    loginCreateAccountState,
    userDetails,
    extraMeta,
    setSharedItineraryId,
    sharedItineraryId,
    shareClick,
    setShareClick,
    selectedChatData,
    chatData,
    appConfig,
    setTrigerredListItineraryAPIFlag,
  }: iGlobalContext = state;

  // Trip name
  const [tripName, setTripName] = React.useState(
    selectedChatData?.tripName || ""
  );

  const [isLoading, setIsLoading] = useState(false);
  async function handleSaveItinerary() {
    toast.dismiss();
    if (isLoading) return;
    setIsLoading(true);
    interface MetaDataType {
      cityName: string;
      averagePackagePriceForExperiencing: string;
      description: string;
      hotelName: string;
      hotelStayCost: string;
      averageCostOfFood: string;
      matchPercentage: string;
      orginatedCity: string;
      selectedMonth: string;
      year: string;
      numberOfDaysSelected: string;
      numberOfExperienceSelected: string;
      planTripArray: any;
    }
    ReactGA.event({
      category: SAVING_ITINERARY.CATEGORY,
      action: SAVING_ITINERARY.ACTION,
      label: ` ${SAVING_ITINERARY.LABEL} ${selectedChatData?.tripName}`,
    });
    // setSavedItinerariesList({ ...singleItineraryDetails, ...extraMeta })

    const metaData: any = {
      chatData: chatData,
      selectedChatData: selectedChatData,
    };
    const response = await saveItineraryDetailsAPI(
      tripName,
      userDetails.id,
      metaData,
      Number(selectedChatData.noOfDays),
      "",
      ""
    );
    if (response.message === "Itineraries has been saved") {
      // toast.success("The itineraries have been saved!");

      // window.sessionStorage.setItem(
      //   "_shared_itinerary_id",
      //   JSON.stringify(response.data.id)
      // );
      toastMessage.success("Your itineray is saved!");
      setSharedItineraryId(`${response.data.id}`);
      setTimeout(next, 500);
      setTimeout(() => {
        if (shareRef.current && shareClick) {
          shareRef.current.children[0].click();
          setShareClick(false);
        }
      }, 400);
    } else
      toastMessage.error(
        getConfigMessage(appConfig, APP_CONFIG.API_FAILURE_MESSAGE.error)
      );
    setIsLoading(false);
    function next() {
      // navigate('/saved-itinerary-list')
      setLoginCreateAccountState({ ...loginCreateAccountState, flag: false });
      console.log("AFTER", metaData);
    }

    // Getting the list of SavedItineraies
    // const listResponse = await getItinerariesListAPI(userDetails.id)
    // if (listResponse.statusCode === 200) {
    //     setSavedItinerariesList(addSwipeFlag(listResponse.data))
    // }
    setTrigerredListItineraryAPIFlag(true);
  }

  // Create a reference to the second button
  const shareRef: any = useRef(null);

  return (
    <Stack
      zIndex={1}
      sx={{ minHeight: "93vh" }}
      spacing={6}
      alignItems="center"
      justifyContent="center">
      <Stack
        sx={{ maxWidth: "350px", transform: "translateY(-30px)" }}
        justifyContent="center"
        alignItems="center">
        <h1 style={{ visibility: "hidden", transform: "translateY(-100px)" }}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem
          laboriosam
        </h1>
        <Stack sx={{ width: "100%" }} px={0.5}>
          <Stack alignSelf="end" mb={1}>
            <IconButton>
              <img
                onClick={handleCancel}
                style={{ width: "35px", cursor: "pointer" }}
                src={cross}
                alt="cross"
              />
            </IconButton>
          </Stack>
          <Stack sx={{ width: "100%" }}>
            <Stack
              sx={{ borderRadius: "8px 8px 0 0", width: "100%" }}
              spacing={3}
              justifyContent="center"
              bgcolor="white"
              padding={5}>
              <Stack alignSelf="start">
                <h6>Save itinerary</h6>
              </Stack>
              <Stack position="relative" alignSelf='start sx={{width: "100%"}}'>
                <h2
                  style={{
                    fontSize: "11px",
                    marginBottom: "5px",
                    width: "100%",
                  }}>
                  TRIP NAME
                </h2>
                <TextField
                  size="small"
                  placeholder="Greek Islands"
                  value={tripName}
                  onChange={(e: any) => {
                    setTripName(e.target.value);
                  }}
                  InputProps={{
                    style: {
                      height: "30px",
                      // width: '280px',
                      width: "100%",
                      fontSize: "12px",
                      color: "#696969",
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      fontSize: "12px",
                    },
                  }}
                />
              </Stack>
            </Stack>
            <Stack
              sx={{ borderRadius: " 0 0 8px 8px", cursor: "pointer" }}
              bgcolor={isLoading ? "#d5d5d5" : "#FCB400"}
              height={60}
              alignItems="center"
              justifyContent="center"
              onClick={handleSaveItinerary}>
              <h6 style={{ color: "#333" }}>SAVE ITINERARY</h6>
            </Stack>

            <div
              ref={shareRef}
              onClick={() => {
                console.log("CHECK FREQ CLICK");
              }}
              style={{ display: "none" }}>
              <RWebShare
                data={{
                  url: `${APP_URL}shared/${sharedItineraryId}`,
                  title: "zenvoya",
                }}
                onClick={() => {
                  ReactGA.event({
                    category: "Button Click",
                    action: `Click on Share Trip Details`,
                    label: `User is sharing the itinerary ${APP_URL}/shared/${sharedItineraryId}`,
                  });
                }}>
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  ref={shareRef}>
                  {/* <img style={{ width: '24px', cursor: 'pointer' }} src={share} alt="share" /> */}
                  <h3>Share</h3>
                </Stack>
              </RWebShare>
            </div>
          </Stack>
        </Stack>
      </Stack>
      {/* <Toaster
                position="top-center"
                reverseOrder={false}
            /> */}
    </Stack>
  );
}
