import React from "react";
// import { chatIcon } from "../../../constant";
import styles from "./loader.module.scss";

function Loader({ small }: { small?: boolean }): JSX.Element {
  return (
    <div className={styles.loadContainer}>
      {/* <div className={styles.systemChatIcon}>
        <img className={styles.chatIcon} alt="chat logo" src={chatIcon} />
        <h3 className={styles.heading}>ZenVoya</h3>
      </div> */}
      <div className={styles.loader}>
        <div className={styles.circle}></div>
        <div className={styles.circle}></div>
        <div className={styles.circle}></div>
        <div className={styles.circle}></div>
      </div>
    </div>
  );
}

export default Loader;
