export const DataPrompts = [
  {
    id: 1,
    title: "7 days trip to Hawaii",
    desc: " With hiking, beaches and unique cultural experiences",
  },
  {
    id: 2,
    title: "7 days trip to Hawaii",
    desc: " With hiking, beaches and unique cultural experiences",
  },
  {
    id: 3,
    title: "7 days trip to Hawaii",
    desc: " With hiking, beaches and unique cultural experiences",
  },
  {
    id: 4,
    title: "7 days trip to Hawaii",
    desc: " With hiking, beaches and unique cultural experiences",
  },
];
