import React, { useEffect } from "react";
import styles from "./selectedTrip.module.scss";
import { zenvoyaChatIcon } from "../../../constant";
import { Button, Grid } from "@mui/material";
import DestinationCard from "../../../component/reusableComponent/destinationCard/DestinationCard";
import { iChatData, iCityDetailsResult } from "../../../types";
import Markdown from "react-markdown";
import MultipleDestinationCard from "../../../component/reusableComponent/multipleDestinationCard/MultipleDestinationCard";

export default function SelectedTrip({
  chatData,
  handleViewTripDetails,
}: {
  chatData: iChatData;
  handleViewTripDetails?: any;
}) {
  useEffect(() => {
    console.log("Here it is", chatData);
  }, [chatData]);

  return (
    <div className={styles.container} onClick={handleViewTripDetails}>
      <div className={styles.systemChatIcon}>
        <img
          className={styles.chatIcon}
          alt="chat logo"
          src={zenvoyaChatIcon}
        />
        <h3 className={styles.heading}>zenvoya</h3>
      </div>
      <Markdown className={styles.postText}>{chatData?.preText}</Markdown>
      <div className={styles.multipleCityContainer}>
        <MultipleDestinationCard destinationList={chatData.destinationList} />
      </div>
    </div>
  );
}
