import axios from "axios";
import { ENDPOINT } from "../constant/environment";

export async function chatInteractionAPI(
  userId: string,
  userInput: string,
  messages: [],
  signal?: AbortSignal
) {
  const URL = `${ENDPOINT}/app/v1/itineraries/search`;
  let config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
    signal,
  };
  let body = {
    userId,
    userInput,
    messages,
  };
  try {
    console.log(body);
    const response = await axios.post(URL, body, config);
    console.log(response);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}
